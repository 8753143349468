import { NgModule } from '@angular/core';
import { PlanShipmentComponent } from './components/plan-shipment/plan-shipment.component';
import { SafePipe } from './pipes/safe.pipe';
import { CancelShipmentComponent } from './components/cancel-shipment/cancel-shipment.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule, DatePipe, NgOptimizedImage } from '@angular/common';
import { SearchPipe } from './pipes/search.pipe';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ErrorPipe } from './pipes/error.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PrintVisualsComponent } from './components/print-visuals/print-visuals.component';
import { AddressBlockComponent } from './components/address-block/address-block.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RestoreOrderComponent } from './components/restore-order/restore-order.component';
import { ForceStatusComponent } from './components/force-status/force-status.component';
import { UpdateAppComponent } from "./components/update-app/update-app.component";
import { ResponsiveButtonComponent } from './components/responsive-button/responsive-button.component';
import { LargeButtonComponent } from './components/large-button/large-button.component';
import { PrintLabelsComponent } from './components/print-labels/print-labels.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatPseudoCheckboxModule } from "@angular/material/core";
import { OrderNavigationComponent } from './components/order-navigation/order-navigation.component';
import { OrderStatePipe } from './pipes/order-state.pipe';
import { EmailLogsComponent } from './components/email-logs/email-logs.component';
import { DragDirective } from './directives/dragDrop.directive';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { SyncShipmentsComponent } from './components/sync-shipments/sync-shipments.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RestoreQuotationComponent } from './components/restore-quotation/restore-quotation.component';
import { StateIndicatorComponent } from "./components/state-indicator/state-indicator.component";
import { LockByEditorComponent } from "./components/lock-by-editor/lock-by-editor.component";
import { OrderInformationComponent } from "./components/order-information/order-information.component";
import { FilesErrorsComponent } from "./components/files-errors/files-errors.component";
import { ConfirmValidationComponent } from "./components/confirm-validation/confirm-validation.component";
import { QuotationMenuComponent } from "./components/quotation-menu/quotation-menu.component";
import { DeleteQuotationComponent } from "./components/delete-quotation/delete-quotation.component";
import { DeleteOrderComponent } from "./components/delete-order/delete-order.component";
import { QuotationInformationComponent } from "./components/quotation-information/quotation-information.component";
import { ShipmentMenuComponent } from "./components/shipment-menu/shipment-menu.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { InvalidLinkComponent } from "./components/invalid-link/invalid-link.component";
import { InstallationMenuComponent } from "./components/installation-menu/installation-menu.component";
import { DeleteInstallationComponent } from "./components/delete-installation/delete-installation.component";
import { DeleteProductionComponent } from "./components/delete-production/delete-production.component";
import { ProductionMenuComponent } from "./components/production-menu/production-menu.component";
import {
  ExternalProductionMenuComponent
} from "./components/external-production-menu/external-production-menu.component";
import {
  DeleteExternalProductionComponent
} from "./components/delete-external-production/delete-external-production.component";
import { ProductionInformationComponent } from "./components/production-information/production-information.component";
import {
  InstallationInformationComponent
} from "./components/installation-information/installation-information.component";
import {
  ExternalProductionInformationComponent
} from "./components/external-production-information/external-production-information.component";
import { SearchComponent } from "./components/search/search.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ProductionFilesComponent } from "./components/production-files/production-files.component";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { UploadButtonComponent } from "./components/upload-button/upload-button.component";
import { ProductBlockComponent } from "./components/product-block/product-block.component";
import { SubcontractRequestsComponent } from "./components/subcontract-requests/subcontract-requests.component";
import {
  DeleteSubcontractRequestComponent
} from "./components/subcontract-requests/delete-subcontract-request/delete-subcontract-request.component";
import { MatListModule } from "@angular/material/list";
import { ProfileComponent } from "./components/profile/profile.component";
import { CustomerOrdersComponent } from "./components/customer-orders/customer-orders.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { CustomerQuotationsComponent } from "./components/customer-quotations/customer-quotations.component";
import {
  SelectSubcontractorComponent
} from "./components/subcontract-requests/select-subcontractor/select-subcontractor.component";
import { TimeTrackingComponent } from "./components/time-tracking/time-tracking.component";
import {
  SendRequestsComponent
} from "./components/send-requests/send-requests.component";
import { AcceptRefuseRequestComponent } from "./components/accept-refuse-request/accept-refuse-request.component";
import { RestoreInstallationComponent } from "./components/restore-installation/restore-installation.component";
import { TerminateOperationComponent } from "./components/terminate-operation/terminate-operation.component";
import { RestoreProductionComponent } from "./components/restore-production/restore-production.component";
import {
  RestoreExternalProductionComponent
} from "./components/restore-external-production/restore-external-production.component";
import { SearchUserComponent } from "./components/search-user/search-user.component";
import { DownloadOrderFilesComponent } from "./components/download-order-files/download-order-files.component";
import { SubcontractorPickerComponent } from "./components/subcontractor-picker/subcontractor-picker.component";
import { CapabilityPickerComponent } from "./components/capability-picker/capability-picker.component";
import { OrderDeliveriesComponent } from "./components/order-deliveries/order-deliveries.component";
import { SearchScriptComponent } from "./components/search-script/search-script.component";

@NgModule({
  declarations: [
    OrderStatePipe,
    ErrorPipe,
    SearchPipe,
    SafePipe,
    DragDirective,
    SearchComponent,
    CancelShipmentComponent,
    PlanShipmentComponent,
    ConfirmEmailComponent,
    PrintVisualsComponent,
    AddressBlockComponent,
    RestoreOrderComponent,
    ForceStatusComponent,
    UpdateAppComponent,
    ResponsiveButtonComponent,
    LargeButtonComponent,
    PrintLabelsComponent,
    OrderNavigationComponent,
    EmailLogsComponent,
    SyncShipmentsComponent,
    RestoreQuotationComponent,
    StateIndicatorComponent,
    LockByEditorComponent,
    OrderInformationComponent,
    FilesErrorsComponent,
    ConfirmValidationComponent,
    QuotationMenuComponent,
    DeleteQuotationComponent,
    DeleteOrderComponent,
    QuotationInformationComponent,
    ShipmentMenuComponent,
    InvalidLinkComponent,
    InstallationMenuComponent,
    DeleteInstallationComponent,
    DeleteProductionComponent,
    ProductionMenuComponent,
    ExternalProductionMenuComponent,
    DeleteExternalProductionComponent,
    ProductionInformationComponent,
    InstallationInformationComponent,
    ProductionFilesComponent,
    ExternalProductionInformationComponent,
    UploadButtonComponent,
    ProductBlockComponent,
    SubcontractRequestsComponent,
    DeleteSubcontractRequestComponent,
    ProfileComponent,
    CustomerOrdersComponent,
    CustomerQuotationsComponent,
    SelectSubcontractorComponent,
    TimeTrackingComponent,
    SendRequestsComponent,
    AcceptRefuseRequestComponent,
    RestoreInstallationComponent,
    TerminateOperationComponent,
    RestoreProductionComponent,
    RestoreExternalProductionComponent,
    SearchUserComponent,
    DownloadOrderFilesComponent,
    SubcontractorPickerComponent,
    CapabilityPickerComponent,
    OrderDeliveriesComponent,
    SearchScriptComponent
  ],
  imports: [
    TranslateModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatProgressBarModule,
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatIconModule,
    MatExpansionModule,
    MatMenuModule,
    MatPseudoCheckboxModule,
    MatChipsModule,
    MatTooltipModule,
    MatPseudoCheckboxModule,
    MatTreeModule,
    MatCardModule,
    RouterLink,
    MatSelectModule,
    MatToolbarModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    NgOptimizedImage,
    MatListModule,
    MatDatepickerModule,
    DatePipe,
  ],
  exports: [
    SearchPipe,
    SafePipe,
    CancelShipmentComponent,
    PlanShipmentComponent,
    ConfirmEmailComponent,
    ErrorPipe,
    OrderStatePipe,
    PrintVisualsComponent,
    AddressBlockComponent,
    RestoreOrderComponent,
    ForceStatusComponent,
    UpdateAppComponent,
    ResponsiveButtonComponent,
    LargeButtonComponent,
    PrintLabelsComponent,
    OrderNavigationComponent,
    EmailLogsComponent,
    DragDirective,
    SyncShipmentsComponent,
    RestoreQuotationComponent,
    StateIndicatorComponent,
    LockByEditorComponent,
    OrderInformationComponent,
    ConfirmValidationComponent,
    QuotationMenuComponent,
    QuotationInformationComponent,
    ShipmentMenuComponent,
    FilesErrorsComponent,
    InvalidLinkComponent,
    InstallationMenuComponent,
    ProductionMenuComponent,
    ExternalProductionMenuComponent,
    ProductionInformationComponent,
    InstallationInformationComponent,
    ExternalProductionInformationComponent,
    SearchComponent,
    ProductionFilesComponent,
    UploadButtonComponent,
    ProductBlockComponent,
    SubcontractRequestsComponent,
    DeleteSubcontractRequestComponent,
    ProfileComponent,
    CustomerOrdersComponent,
    CustomerQuotationsComponent,
    TimeTrackingComponent,
    AcceptRefuseRequestComponent,
    TerminateOperationComponent,
    SearchUserComponent,
    DownloadOrderFilesComponent,
    SubcontractorPickerComponent,
    CapabilityPickerComponent,
    OrderDeliveriesComponent,
    SearchScriptComponent
  ],
  providers: [
    ErrorPipe, OrderStatePipe
  ]
})

export class SharedModule {
}
