import { FirestoreDataConverter } from '@angular/fire/firestore';
import { Settings } from './settings.base';
import { SettingsEnum } from '../enums/settings.enum';
import { ObjectHelper } from "../helpers/object.helper";

export class MultipressSettings extends Settings {
  type = SettingsEnum.MULTIPRESS;
  /** List of statuses for state Wait for quotation */
  waitQuotationStates: string[];
  /** Status for new quotation */
  quotationNewState: string;
  /** Status for state Wait for quotation validation */
  quotationValidationState: string;
  /** List of statuses for state Wait for file */
  waitFileStates: string[];
  /** Status for state Wait for file */
  waitFileState: string;
  /** The state for To be process */
  processingState: string;
  /** id of the checklist to indicate if a sub job has a file to process */
  filesId: string;
  /** id of the checklist to indicate if a sub job has a callas script */
  scriptId: string;
  /** id of the checklist to indicate the sub job format */
  formatId: string;
  /** id of the checklist to indicate the production information */
  productionInformationId: string;
  /** id of the subjob standard to indicate a sold express delivery */
  expressDeliveryId: string;
  /** id of the MP Shipment delivery method to indicate delivery is a pickup  */
  pickUpDeliveryId: string;
  /** id of the MP Shipment delivery method to indicate delivery is a courier */
  courierDeliveryId: string;
  /** id of the MP Shipment delivery method to indicate delivery is a charter */
  charterDeliveryId: string;
  /** State for Quotation accepted */
  quotationAcceptedState: string;
  /** State for Quotation refused */
  quotationRefusedState: string;
  /** State for BAT accepted */
  batAcceptedState: string;
  /** State for BAT refused */
  batRefusedState: string;
  /** State for wait for BAT */
  waitBatState: string;
  /** State for Archived */
  archiveState: string;
  /** id of the checklist to indicate the of contacts */
  contactsId: string;
  /** id of the checklist to link support */
  supportId: string;
  /** id of the checklist to link finishing */
  finishingId: string;
  /** State for ready to ship */
  readyToShipState: string;
  /** State for ready to invoice */
  readyToInvoiceState: string;
  /** State for shipped */
  shippedState: string;
  /** State for unpaid order */
  unpaidState: string;
  /** Automatic handling of stock by MP */
  automaticStock: boolean;
  syncQuotations: boolean;
  syncQuotationStartId: number;
  syncOrders: boolean;
  syncOrdersStartId: number;
  syncShipments: boolean;
  shipmentsRetention: number;
  syncInvoices: boolean;
  syncInvoicesStartId: number;
  stockStates: string[];
  url: string;
  login: string;
  password: string;
  isActive: boolean;
  /** Allowed MP SubJobs descriptions without a price */
  allowedNoPriceProducts: string[];
  /** Blocking MP SubJobs descriptions */
  blockingInvoicingProducts: string[];
  /** Blocking MP production types descriptions */
  blockingInvoicingProductionTypes: string[];
  /** id of the checklist to link conditioning */
  conditioningId: string;
  /** String value for internal production type */
  productionKey: number;
  /** String value for external production type */
  externalProductionKey: number;
  /** String value for installation type */
  installationKey: number;
  /** String value for transport type */
  transportKey: number;
  syncProductions: boolean;
  syncInstallations: boolean;
  syncExternalProductions: boolean;

  constructor(data?: Partial<MultipressSettings>) {
    super(data);
    this.waitQuotationStates = data?.waitQuotationStates ?? [];
    this.quotationValidationState = data?.quotationValidationState ?? '';
    this.quotationNewState = data?.quotationNewState ?? '';
    this.waitFileStates = data?.waitFileStates ?? [];
    this.processingState = data?.processingState ?? '';
    this.filesId = data?.filesId ?? '';
    this.scriptId = data?.scriptId ?? '';
    this.formatId = data?.formatId ?? '';
    this.quotationAcceptedState = data?.quotationAcceptedState ?? '';
    this.quotationRefusedState = data?.quotationRefusedState ?? '';
    this.batAcceptedState = data?.batAcceptedState ?? '';
    this.batRefusedState = data?.batRefusedState ?? '';
    this.waitFileState = data?.waitFileState ?? '';
    this.waitBatState = data?.waitBatState ?? '';
    this.archiveState = data?.archiveState ?? '';
    this.contactsId = data?.contactsId ?? '';
    this.readyToShipState = data?.readyToShipState ?? '';
    this.readyToInvoiceState = data?.readyToInvoiceState ?? '';
    this.shippedState = data?.shippedState ?? '';
    this.automaticStock = data?.automaticStock ?? false;
    this.syncQuotations = data?.syncQuotations ?? false;
    this.syncQuotationStartId = data?.syncQuotationStartId ?? 0;
    this.syncOrders = data?.syncOrders ?? false;
    this.syncOrdersStartId = data?.syncOrdersStartId ?? 0;
    this.syncShipments = data?.syncShipments ?? false;
    this.shipmentsRetention = data?.shipmentsRetention ?? 30;
    this.syncInvoices = data?.syncInvoices ?? false;
    this.syncInvoicesStartId = data?.syncInvoicesStartId ?? 0;
    this.stockStates = data?.stockStates ?? [];
    this.productionInformationId = data?.productionInformationId ?? '';
    this.expressDeliveryId = data?.expressDeliveryId ?? '';
    this.pickUpDeliveryId = data?.pickUpDeliveryId ?? '';
    this.courierDeliveryId = data?.courierDeliveryId ?? '';
    this.charterDeliveryId = data?.charterDeliveryId ?? '';
    this.url = data?.url ?? '';
    this.login = data?.login ?? '';
    this.password = data?.password ?? '';
    this.unpaidState = data?.unpaidState ?? '';
    this.supportId = data?.supportId ?? '';
    this.finishingId = data?.finishingId ?? '';
    this.isActive = data?.isActive ?? false;
    this.allowedNoPriceProducts = data?.allowedNoPriceProducts ?? [];
    this.blockingInvoicingProducts = data?.blockingInvoicingProducts ?? [];
    this.blockingInvoicingProductionTypes = data?.blockingInvoicingProductionTypes ?? [];
    this.conditioningId = data?.conditioningId ?? '';
    this.productionKey = data?.productionKey ?? -1;
    this.externalProductionKey = data?.externalProductionKey ?? -1;
    this.installationKey = data?.installationKey ?? -1;
    this.transportKey = data?.transportKey ?? -1;
    this.syncProductions = data?.syncProductions ?? false;
    this.syncInstallations = data?.syncInstallations ?? false;
    this.syncExternalProductions = data?.syncExternalProductions ?? false;
  }

  converter: FirestoreDataConverter<MultipressSettings> = {
    toFirestore: (settings: MultipressSettings) => {
      settings.transportKey = Number(settings.transportKey);
      settings.productionKey = Number(settings.productionKey);
      settings.externalProductionKey = Number(settings.externalProductionKey);
      settings.installationKey = Number(settings.installationKey);
      const obj = ObjectHelper.anonymize(settings);
      delete obj.converter;
      delete obj.type;
      delete obj.id;
      return obj;
    },
    fromFirestore: (snapshot, options) =>
      new MultipressSettings({ ...snapshot.data(options), id: snapshot.id })
  };
}
