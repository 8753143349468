import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { UxLogger } from './shared/services/uxlogger.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './shared/services/data.service';
import { AuthService } from './shared/services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { RolesEnum } from './shared/enums/roles.enum';
import { ThemeService } from './shared/services/theme.service';
import { Observable, Subscription } from 'rxjs';
import { Option } from './shared/models/option.model';
import packageInfo from '../../package.json';
import { OrderService } from './shared/services/order.service';
import { OrganizationService } from "./shared/services/organization.service";
import { environment } from "../environments/environment";
import { SubcontractorService } from "./shared/services/subcontractor.service";
import { Organization } from "./shared/models/organization.model";
import { PlanEnum } from "./shared/enums/plan.enum";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  protected readonly environment = environment;

  private translate = inject(TranslateService);
  private snackBar = inject(MatSnackBar);
  private logger = inject(NGXLogger);
  private responsive = inject(BreakpointObserver);
  private themeService = inject(ThemeService);
  private orderService = inject(OrderService);
  dataService = inject(DataService);
  router = inject(Router);
  dialog = inject(MatDialog);
  authService = inject(AuthService);
  subcontractorService = inject(SubcontractorService);
  private subscriptions: Subscription[];
  options$: Observable<Array<Option>> = this.themeService.getThemeOptions();
  smallLayout = false;
  version: string = packageInfo.version;
  menuOpened = true;
  RolesEnum = RolesEnum;
  searchedValue = '';
  organizationName = '';
  organizationService = inject(OrganizationService);
  organizationNames: Record<string, string> = {};
  userOrganizations: Organization[] = [];
  protected readonly PlanEnum = PlanEnum;

  public ngOnInit(): void {
    this.menuOpened = !this.themeService.smallLayout;
    registerLocaleData(localeFr, 'fr');
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    this.logger.registerMonitor(new UxLogger(this.translate, this.snackBar, this.dialog));
    this.subscriptions = [
      this.responsive.observe(Breakpoints.XSmall)
        .subscribe(result => {
          this.smallLayout = result.matches;
        }),
      this.router.events.subscribe(evt => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      }),
      this.organizationService.organization$.subscribe(organization => {
        if (!organization?.id) {
          this.organizationName = '';
          return
        }
        this.subcontractorService.getByOrgId(organization.id)
          .then(sub => this.organizationName = sub.name);
      })
    ];
    if (localStorage.getItem('theme')) {
      this.themeService.setTheme(localStorage.getItem('theme'));
    } else {
      this.themeService.setTheme('deeppurple-amber');
    }
    this.authService.auth.onAuthStateChanged(user => {
      if (!user || user.isAnonymous) {
        this.organizationNames = {};
        this.userOrganizations = [];
        return;
      }
      this.loadUserOrganisations()
    })
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public themeChangeHandler(themeToSet): void {
    this.themeService.setTheme(themeToSet);
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme) {
      localStorage.removeItem('theme');
    }
    localStorage.setItem('theme', themeToSet);
  }

  public search(): void {
    this.orderService.getOrderByTransactionId(this.searchedValue)
      .then(order => {
        if (!order) {
          this.snackBar.open(this.translate.instant('order.notFound.message'), 'x')
        }
        return this.router.navigate([this.organizationService.currentOrganization.oid, 'order', order.id]);
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public goToAction(): void {
    if (!this.smallLayout) {
      return;
    }
    this.menuOpened = false;
  }

  menuDisplayed(): boolean {
    return this.authService.isLoggedIn &&
      !this.router.url.includes('auth') &&
      this.router.url !== '/' &&
      (
        this.organizationService.currentOrganization?.users.includes(this.authService.currentUser.id) ||
        this.organizationService.currentOrganization?.administrators.includes(this.authService.currentUser.id)
      );
  }

  /**
   * Load organizations of the connected user
   */
  private loadUserOrganisations(): void {
    Promise.all([
      this.organizationService.listUserOrganizations(this.authService.getUid),
      this.organizationService.listAdminOrganizations(this.authService.getUid)
    ])
      .then(async ([userOrganizations, organizations]) => {
        const allOrgs = [...userOrganizations, ...organizations];
        await Promise.all(allOrgs
          .map(org => this.subcontractorService.getByOrgId(org.id)
            .then(sub => [org.id, sub.name])
          )
        ).then(entries => this.organizationNames = Object.fromEntries(entries));
        this.userOrganizations = allOrgs;
      });
  }

  /**
   * Go to organisation dashboard
   * @param organization The selected organisation
   * @returns Router navigate result
   */
  goToOrganization(organization: Organization): Promise<boolean> {
    this.organizationService.currentOrganization = organization;
    return this.router.navigate(['/', organization.oid, 'dashboard']);
  }
}
