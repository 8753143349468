import { FirestoreDataConverter } from "@angular/fire/firestore";
import { PlanEnum } from "../enums/plan.enum";

/**
 * Organization class
 */
export class Organization {
  id: string;
  oid: string;
  administrators: string[];
  users: string[];
  plan: PlanEnum;
  main: boolean;
  plugins: string[];

  constructor(organization?: Partial<Organization>) {
    this.id = organization?.id ?? '';
    this.oid = organization?.oid ?? '';
    this.administrators = organization?.administrators ?? [];
    this.users = organization?.users ?? [];
    this.plan = organization?.plan ?? PlanEnum.FREE;
    this.main = organization?.main ?? false;
    this.plugins = organization?.plugins ?? [];
  }

  /**
   * Converter used to convert an organization from firebase
   */
  static converter: FirestoreDataConverter<Organization> = {
    toFirestore: (organization: Organization) => ({
      id: organization.id ,
      oid: organization.oid,
      administrators: organization.administrators,
      users: organization.users,
      plan: organization.plan,
      main: organization.main,
      plugins: organization.plugins,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Organization({
        ...data,
        id: snapshot.id,
      });
    }
  }
}
