import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  collection, deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs, limit,
  Query,
  query,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { ObjectHelper } from "../helpers/object.helper";
import { Subcontractor } from "../models/subcontractor.model";

@Injectable({
  providedIn: 'root',
})
export class SubcontractorService {

  private firestore = inject(Firestore);

  /**
   * Subscribe to machines collection
   * @returns Machines subscription query
   */
  list(): Query<Subcontractor> {
    return query(collection(this.firestore, 'subcontractors'), where('active', '==', true))
      .withConverter(Subcontractor.converter);
  }

  /**
   * Get subcontractor by capability
   * @param organizationId The organization id
   * @param id Business id to check
   * @returns True if erp id is already used
   */
  async byCapability(organizationId: string, id: string): Promise<Subcontractor[]> {
    const q = query(collection(this.firestore, 'subcontractors'),
      where('capabilities', 'array-contains', id), where('organizationId', '==', organizationId))
      .withConverter(Subcontractor.converter);
    const get = await getDocs(q);
    return get.docs.map(d => d.data())
  }

  /**
   * Create a subcontractor
   * @param subcontractor The machine to save
   * @returns Id of the created machine
   */
  async create(subcontractor: Subcontractor): Promise<string> {
    const ref = collection(this.firestore, 'subcontractors').withConverter(Subcontractor.converter);
    const created = await addDoc(ref, subcontractor);
    return created.id;
  }

  /**
   * Update a machine
   * @param subcontractor The machine to save
   */
  async update(subcontractor: Partial<Subcontractor>): Promise<void> {
    const ref = doc(this.firestore, 'subcontractors', subcontractor.id).withConverter(Subcontractor.converter);
    await updateDoc(ref, ObjectHelper.anonymize(subcontractor));
  }

  /**
   * Get a subcontractor by id
   * @param id The subcontractor id
   * @returns The subcontractor or null
   */
  async get(id: string): Promise<Subcontractor | null> {
    const ref = doc(this.firestore, 'subcontractors', id).withConverter(Subcontractor.converter);
    const document = await getDoc(ref);
    return document.exists() ? document.data() : null;
  }

  /**
   * Delete a subcontractor by id
   * @param id The subcontractor id
   */
  async delete(id: string): Promise<void> {
    const ref = doc(this.firestore, 'subcontractors', id).withConverter(Subcontractor.converter);
    await deleteDoc(ref);
  }

  /**
   * Get a subcontractor by org id
   * @param orgId The org id
   * @returns The subcontractor or null
   */
  async getByOrgId(orgId: string): Promise<Subcontractor | null> {
    const ref = query(
      collection(this.firestore, 'subcontractors'),
      where('organizationId', '==', orgId),
      limit(1)
    )
      .withConverter(Subcontractor.converter);
    const snapshot = await getDocs(ref);
    return snapshot.docs[0]?.data() ?? null;

  }
}
