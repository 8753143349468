import { FirestoreDataConverter } from '@angular/fire/firestore';
import { UnitTypeEnum } from '../enums/unit-type.enum';
import { ProductionTypeEnum } from "../enums/production-type.enum";
import { FormControl, FormGroup } from "@angular/forms";

export type VariationForm = FormGroup<{
  id: FormControl<string>,
  name: FormControl<string>,
  erpId: FormControl<string>,
  material: FormControl<string | null>,
  printType: FormControl<string | null>,
  format: FormControl<string | null>,
  finish: FormControl<string | null>,
  cutting: FormControl<string | null>,
  drilling: FormControl<string | null>,
  coverMaterial: FormControl<string | null>,
  binding: FormControl<string | null>,
  folding: FormControl<string | null>,
  script: FormControl<string>,
  unit: FormControl<UnitTypeEnum>,
  dynamic: FormControl<boolean>,
  pageNumber: FormControl<number>,
  productionType: FormControl<ProductionTypeEnum>,
  subcontractorId: FormControl<string>,
  machineId: FormControl<string>,
  organizationId: FormControl<string>,
}>;

/**
 * Model for Commerce Product Variation (Used for Commerce)
 */
export class CommerceVariation {
  id: string;
  name: string;
  erpId: string;
  material: string | null;
  printType: string | null;
  format: string | null;
  finish: string | null;
  cutting: string | null;
  drilling: string | null;
  binding: string | null;
  folding: string | null;
  coverMaterial: string | null;
  script: string;
  unit: UnitTypeEnum;
  dynamic: boolean;
  pageNumber: number;
  productionType: ProductionTypeEnum;
  subcontractorId: string;
  machineId: string;
  organizationId: string;

  constructor(data?: Partial<CommerceVariation>) {
    this.id = data?.id ?? '';
    this.name = data?.name ?? '';
    this.erpId = data?.erpId ?? '';
    this.material = data?.material ?? null;
    this.printType = data?.printType ?? null;
    this.format = data?.format ?? null;
    this.finish = data?.finish ?? null;
    this.cutting = data?.cutting ?? null;
    this.drilling = data?.drilling ?? null;
    this.binding = data?.binding ?? null;
    this.folding = data?.folding ?? null;
    this.coverMaterial = data?.coverMaterial ?? null;
    this.script = data?.script ?? '';
    this.unit = data?.unit ?? UnitTypeEnum.Unit;
    this.dynamic = data?.dynamic ?? false;
    this.pageNumber = data?.pageNumber ?? 0;
    this.productionType = data?.productionType ?? ProductionTypeEnum.INTERNAL;
    this.subcontractorId = data?.subcontractorId ?? '';
    this.machineId = data?.machineId ?? '';
    this.organizationId = data?.organizationId ?? '';
  }

  /**
   * Converter used to convert a variation from firebase
   */
  static converter: FirestoreDataConverter<CommerceVariation> = {
    toFirestore: (variation: CommerceVariation) => {
      return {
        id: variation.id,
        name: variation.name,
        erpId: variation.erpId,
        material: variation.material,
        printType: variation.printType,
        format: variation.format,
        finish: variation.finish,
        cutting: variation.cutting,
        drilling: variation.drilling,
        binding: variation.binding,
        folding: variation.folding,
        coverMaterial: variation.coverMaterial,
        script: variation.script,
        unit: variation.unit,
        dynamic: variation.dynamic,
        pageNumber: variation.pageNumber,
        productionType: variation.productionType,
        subcontractorId: variation.subcontractorId,
        machineId: variation.machineId,
        organizationId: variation.organizationId,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new CommerceVariation({
        ...data,
        id: snapshot.id,
      });
    }
  };
}
