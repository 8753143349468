import { Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import moment from 'moment';
import { User } from "../models/user.model";
import { QuotationState } from '../enums/quotation-state.enum';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private searchControl = new FormControl('', { nonNullable: true });
  private quotationStatesControl = new FormControl<QuotationState[]>([], { nonNullable: true });
  private shipDateRangeGroup = new FormGroup({
    start: new FormControl(moment().subtract(7, 'days'), { nonNullable: true }),
    end: new FormControl(moment().add(2, 'days'), { nonNullable: true }),
  });
  private siteIdControl = new FormControl('', { nonNullable: true });
  dataLoading = false;
  currentFilter = '';
  usersControl = new FormControl<User[]>([], { nonNullable: true });

  // TODO: Not typable because of the fake -1 state
  public statesControl = new UntypedFormControl();
  public showDisabled = false;
  public shipmentPreQueryFilterForm = new FormGroup({
    shipDateRange: this.shipDateRangeGroup,
    siteId: this.siteIdControl
  });
  public quotationFilterForm = new FormGroup({
    search: this.searchControl,
    sales: this.usersControl,
    states: this.quotationStatesControl,
  });
}
