import { FirestoreDataConverter } from "@angular/fire/firestore";
import { DocumentModel, DocumentModelForm } from "./document.model";
import { FormArray, FormControl } from "@angular/forms";
import { ObjectHelper } from "../helpers/object.helper";

export type SubcontractorForm = {
  id: FormControl<string>;
  organizationId: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string>;
  coverages: FormControl<string[]>;
  documents: FormArray<DocumentModelForm>;
  capabilities: FormControl<string[]>;
  active: FormControl<boolean>;
}

export class Subcontractor {
  id: string;
  organizationId: string;
  name: string;
  description: string;
  coverages: string[];
  documents: DocumentModel[];
  capabilities: string[];
  active: boolean;

  constructor(subcontractor?: Partial<Subcontractor>) {
    this.id = subcontractor?.id ?? '';
    this.organizationId = subcontractor?.organizationId ?? '';
    this.name = subcontractor?.name ?? '';
    this.description = subcontractor?.description ?? '';
    this.coverages = subcontractor?.coverages ?? [];
    this.documents = subcontractor?.documents?.map(d => new DocumentModel(d)) ?? [];
    this.capabilities = subcontractor?.capabilities ?? [];
    this.active = subcontractor?.active ?? false;
  }

  /**
   * Converter used to convert object from firebase
   */
  static converter: FirestoreDataConverter<Subcontractor> = {
    toFirestore: (subcontractor: Subcontractor) => ({
      id: subcontractor.id,
      organizationId: subcontractor.organizationId,
      name: subcontractor.name,
      description: subcontractor.description,
      coverages: subcontractor.coverages,
      documents: ObjectHelper.anonymize(subcontractor.documents),
      capabilities: subcontractor.capabilities,
      active: subcontractor.active,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Subcontractor({ ...data, id: snapshot.id });
    }
  };
}
