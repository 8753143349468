import { FirestoreDataConverter } from '@angular/fire/firestore';
import { Settings } from './settings.base';
import { SettingsEnum } from '../enums/settings.enum';
import { Address } from './address.model';
import { ObjectHelper } from "../helpers/object.helper";

export class TransportSettings extends Settings {
  converter: FirestoreDataConverter<TransportSettings> = {
    toFirestore: (settings: TransportSettings) => {
      const obj = ObjectHelper.anonymize(settings);
      delete obj.converter;
      delete obj.type;
      delete obj.id;
      return obj;
    },
    fromFirestore: (snapshot, options) =>
      new TransportSettings({ ...snapshot.data(options), id: snapshot.id })
  };
  type = SettingsEnum.TRANSPORT;
  isActive: boolean;
  siteSettings: SiteSettings[];

  constructor(data?: Partial<TransportSettings>) {
    super(data);
    this.siteSettings = (data?.siteSettings ?? []).map(siteSetting => new SiteSettings(siteSetting));
    this.isActive = data?.isActive ?? false;
  }
}

export class SiteSettings {
  site: string;
  address: Address;
  geodis: GeodisTransportSiteSetting & CommonTransportSiteSetting;
  dpd: DpdTransportSiteSetting & CommonTransportSiteSetting;
  schenker: SchenkerTransportSiteSetting & CommonTransportSiteSetting;
  kuehne: KuehneTransportSiteSetting & CommonTransportSiteSetting;
  tnt: TntTransportSiteSetting & CommonTransportSiteSetting;

  constructor(data?: Partial<SiteSettings>) {
    this.site = data?.site ?? '';
    this.address = new Address(data?.address);
    this.geodis = new GeodisTransportSiteSetting(data?.geodis);
    this.dpd = new DpdTransportSiteSetting(data?.dpd);
    this.schenker = new SchenkerTransportSiteSetting(data?.schenker);
    this.kuehne = new KuehneTransportSiteSetting(data?.kuehne);
    this.tnt = new TntTransportSiteSetting(data?.tnt);
  }
}

export abstract class CommonTransportSiteSetting {
  enabled: boolean;
  closingTime: number;
  erpId: string;
  taxes: number;

  protected constructor(data?: Partial<CommonTransportSiteSetting>) {
    this.enabled = data?.enabled ?? false;
    this.closingTime = data?.closingTime ?? 0;
    this.erpId = data?.erpId ?? '';
    this.taxes = data?.taxes ?? 0;
  }
}

export class GeodisTransportSiteSetting extends CommonTransportSiteSetting {
  code: string;
  customerCode: string;
  eori: string;
  login: string;
  url: string;
  key: string;
  wsKey: string;

  constructor(data?: Partial<GeodisTransportSiteSetting>) {
    super(data);
    this.code = data?.code ?? '';
    this.customerCode = data?.customerCode ?? '';
    this.eori = data?.eori ?? '';
    this.login = data?.login ?? '';
    this.url = data?.url ?? '';
    this.key = data?.key ?? '';
    this.wsKey = data?.wsKey ?? '';
  }
}

export class DpdTransportSiteSetting extends CommonTransportSiteSetting {
  countryCode: string;
  centerNumber: string;
  customerNumber: string;
  login: string;
  password: string;
  url: string;
  traceUrl: string;

  constructor(data?: Partial<DpdTransportSiteSetting>) {
    super(data);
    this.countryCode = data?.countryCode ?? '';
    this.centerNumber = data?.centerNumber ?? '';
    this.customerNumber = data?.customerNumber ?? '';
    this.login = data?.login ?? '';
    this.password = data?.password ?? '';
    this.url = data?.url ?? '';
    this.traceUrl = data?.traceUrl ?? '';
  }
}

export class SchenkerTransportSiteSetting extends CommonTransportSiteSetting {
  accountNumber: string;
  url: string;
  traceUrl: string;
  token: string;
  accessKey: string;

  constructor(data?: Partial<SchenkerTransportSiteSetting>) {
    super(data);
    this.accountNumber = data?.accountNumber ?? '';
    this.url = data?.url ?? '';
    this.traceUrl = data?.traceUrl ?? '';
    this.token = data?.token ?? '';
    this.accessKey = data?.accessKey ?? '';
  }
}

export class KuehneTransportSiteSetting extends CommonTransportSiteSetting {
  login: string;
  url: string;
  password: string;
  customerCode: string;
  token: string;
  trackingUrl: string;
  trackingCustomerCode: string;

  constructor(data?: Partial<KuehneTransportSiteSetting>) {
    super(data);
    this.login = data?.login ?? '';
    this.url = data?.url ?? '';
    this.password = data?.password ?? '';
    this.customerCode = data?.customerCode ?? '';
    this.token = data?.token ?? '';
    this.trackingUrl = data?.trackingUrl ?? '';
    this.trackingCustomerCode = data?.trackingCustomerCode ?? '';
  }
}

export class TntTransportSiteSetting extends CommonTransportSiteSetting {
  login: string;
  url: string;
  password: string;
  accountNumber: string;

  constructor(data?: Partial<TntTransportSiteSetting>) {
    super(data);
    this.login = data?.login ?? '';
    this.url = data?.url ?? '';
    this.password = data?.password ?? '';
    this.accountNumber = data?.accountNumber ?? '';
  }
}
