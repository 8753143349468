import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type FoldingForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  description: FormControl<string>;
  organizationId: FormControl<string>;
}>

export class Folding {
  id: string;
  erpId: string;
  description: string;
  organizationId: string;

  constructor(data?: Partial<Folding>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(folding: Folding): Folding {
    return {
      id: folding.id,
      erpId: folding.erpId,
      description: folding.description,
      organizationId: folding.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot): Folding {
    return new Folding(snapToData(snapshot, { idField: 'id' }));
  }
}
