import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, APP_INITIALIZER, NgModule, Injector } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { provideStorage, getStorage, connectStorageEmulator, Storage } from '@angular/fire/storage';
import { CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from './notfound/notfound.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { LoggerComponent } from './logger/logger.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  ScreenTrackingService,
  provideAnalytics,
  getAnalytics,
  UserTrackingService,
  Analytics, isSupported
} from '@angular/fire/analytics';
import {
  provideFirestore, connectFirestoreEmulator, initializeFirestore, persistentLocalCache,
  persistentMultipleTabManager, Firestore
} from '@angular/fire/firestore';
import { provideFunctions, getFunctions, connectFunctionsEmulator, Functions } from '@angular/fire/functions';
import {
  provideAuth,
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence,
  Auth
} from '@angular/fire/auth';
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MenuComponent } from './menu/menu.component';
import { FirebaseApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { providePerformance, getPerformance, Performance } from '@angular/fire/performance';
import { FirebaseAuthInterceptor } from "./shared/http-interceptors/firebase-auth.interceptor";
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatInputModule } from '@angular/material/input';
import { UpToDateService } from "./shared/services/up-to-date.service";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SharedModule } from "./shared/shared.module";
import { MatCardModule } from "@angular/material/card";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { AuthService } from "./shared/services/auth.service";
import { QuillModule } from "ngx-quill";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoggerComponent,
    MenuComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.DEBUG,
    }),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot(),
    provideFirebaseApp(() => {
      return initializeApp(environment.firebase)
    }),
    provideAuth(injector => {
      const auth = initializeAuth(injector.get(FirebaseApp), {
        persistence: indexedDBLocalPersistence,
      });
      if (!environment.production) {
        connectAuthEmulator(auth, `http://localhost:9099`);
      }
      return auth;
    }),
    provideAnalytics(injector => {
      return getAnalytics(injector.get(FirebaseApp))
    }),
    provideFirestore(injector => {
      const firestore = initializeFirestore(injector.get(FirebaseApp), {
        localCache: persistentLocalCache({
          tabManager: persistentMultipleTabManager(),
        }),
      });
      if (!environment.production) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }
      return (firestore);
    }),
    provideFunctions(injector => {
      const functions = getFunctions(injector.get(FirebaseApp), 'europe-west1');
      functions.region = 'europe-west1';
      if (!environment.production) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return (functions);
    }),
    providePerformance(injector => {
      return getPerformance(injector.get(FirebaseApp))
    }),
    provideStorage(injector => {
      const storage = getStorage(injector.get(FirebaseApp));
      if (!environment.production) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return (storage);
    }),
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatExpansionModule,
    NgOptimizedImage,
    MatCheckboxModule,
    SharedModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule
  ],
  providers: [
    CurrencyPipe,
    UserTrackingService,
    ScreenTrackingService,
    { provide: MatPaginatorIntl, useValue: CustomPaginator() },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { strict: true } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'center', verticalPosition: 'top' } },
    { provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthInterceptor, multi: true },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: APP_INITIALIZER, useFactory: () => (): void => undefined, deps: [UpToDateService], multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [Injector, Storage, Auth, Firestore, Functions, Performance],
      useFactory: loadAuth,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    },
  ],
  bootstrap: [AppComponent]
})

/**
 * Main application module declaration
 */
export class AppModule {
}

/**
 * Init data
 * @param injector The Injector
 * @returns The promise
 */
export function loadAuth(injector: Injector): () => Promise<void> {
  return async (): Promise<void> => {
    if (await isSupported()) {
      injector.get(Analytics)
    }
    await injector.get(AuthService).waitAuth();
  }
}
/**
 * Load json translation files
 * @param http The angular core http client
 * @returns The httpLoader object
 */
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Customize paginator
 * @returns The paginator with new style
 */
export function CustomPaginator(): MatPaginatorIntl {
  const customPaginatorIntl = new MatPaginatorIntl();
  customPaginatorIntl.itemsPerPageLabel = '';
  return customPaginatorIntl;
}
