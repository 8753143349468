import { UnitTypeEnum } from '../enums/unit-type.enum';
import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type MaterialForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  description: FormControl<string>;
  width: FormControl<number>;
  weight: FormControl<number>;
  unitType: FormControl<UnitTypeEnum>;
  aspect: FormControl<string>;
  organizationId: FormControl<string>;
}>


export class Material {
  id: string;
  erpId: string;
  description: string;
  width: number;
  weight: number;
  unitType: UnitTypeEnum;
  aspect: string;
  organizationId: string;

  constructor(data?: Partial<Material>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.width = data?.width ?? 0;
    this.weight = data?.weight ?? 0;
    this.unitType = data?.unitType ?? UnitTypeEnum.Unit;
    this.aspect = data?.aspect ?? '';
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(material: Material) {
    return {
      id: material.id,
      erpId: material.erpId,
      description: material.description,
      width: material.width,
      weight: material.weight,
      unitType: material.unitType,
      aspect: material.aspect,
      organizationId: material.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Material(snapToData(snapshot, {idField: 'id'}));
  }
}
