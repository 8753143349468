import { FirestoreDataConverter } from '@angular/fire/firestore';
import { ProductOrder } from './product-order.model';
import { QualityError } from "./quality-error.model";
import { OrderState } from "../enums/order-state.enum";
import { ObjectHelper } from "../helpers/object.helper";
import { SourceType } from "../enums/source-type.enum";

/**
 * Order class
 */
export class Order {
  id: string;
  products: ProductOrder[];
  name: string;
  managerId: string;
  salesId: string;
  paid: boolean;
  canceled: boolean;
  amount: number;
  reference: string;
  transactionId: string;
  batIsActive: boolean;
  fileCheckError: boolean;
  fileCheckErrorCode: string;
  batVersion: number;
  new: boolean;
  print: boolean;
  archived: boolean;
  printNumber: number;
  disabled: boolean;
  lockComment: string;
  lockedBy: string;
  lockerName: string;
  contactName: string;
  managerEmail: string;
  salesEmail: string;
  managerName: string;
  salesName: string;
  customerId: string;
  customerEmails: string[];
  company: string;
  emailIsActive: boolean;
  emailFile: number;
  emailBat: number;
  emailInvoice: number;
  /**@deprecated**/
  emailOrder: number;
  batMailSent: boolean;
  mailDate: Date;
  date: Date;
  batDate: Date;
  uploadsDate: Date;
  processDate: Date;
  startDeliveryDate: Date;
  state: OrderState;
  invoiceNumber: string;
  printDate: Date;
  prodPlant: string;
  qualityErrors: QualityError[];
  lockDate: Date;
  organizationId: string;
  source: SourceType;
  webOrderId: string;
  userId: string;

  constructor(order?: Partial<Order>) {
    this.id = order?.id ?? '';
    this.products = (order?.products ?? []).map(product => new ProductOrder(product));
    this.userId = order?.userId ?? '';
    this.webOrderId = order?.webOrderId ?? '';
    this.name = order?.name ?? '';
    this.managerId = order?.managerId ?? '';
    this.salesId = order?.salesId ?? '';
    this.paid = order?.paid ?? false;
    this.canceled = order?.canceled ?? false;
    this.amount = order?.amount ?? 0;
    this.reference = order?.reference ?? '';
    this.transactionId = order?.transactionId ?? '';
    this.batIsActive = order?.batIsActive ?? true;
    this.fileCheckError = order?.fileCheckError ?? false;
    this.fileCheckErrorCode = order?.fileCheckErrorCode ?? '';
    this.batVersion = order?.batVersion ?? 0;
    this.new = order?.new ?? false;
    this.emailIsActive = order?.emailIsActive ?? true;
    this.emailFile = order?.emailFile ?? 0;
    this.emailBat = order?.emailBat ?? 0;
    this.emailInvoice = order?.emailInvoice ?? 0;
    this.emailOrder = order?.emailOrder ?? 0;
    this.print = order?.print ?? false;
    this.archived = order?.archived ?? false;
    this.printNumber = order?.printNumber ?? 3;
    this.disabled = order?.disabled ?? false;
    this.lockComment = order?.lockComment ?? '';
    this.lockedBy = order?.lockedBy ?? 'none';
    this.lockerName = order?.lockerName ?? '';
    this.contactName = order?.contactName ?? '';
    this.managerEmail = order?.managerEmail ?? '';
    this.salesEmail = order?.salesEmail ?? '';
    this.managerName = order?.managerName ?? '';
    this.salesName = order?.salesName ?? '';
    this.customerEmails = order?.customerEmails ?? [];
    this.customerId = order?.customerId ?? '';
    this.company = order?.company ?? '';
    this.batMailSent = order?.batMailSent ?? false;
    this.mailDate = order?.mailDate ?? null;
    this.date = order?.date ?? null;
    this.batDate = order?.batDate ?? null;
    this.uploadsDate = order?.uploadsDate ?? null;
    this.processDate = order?.processDate ?? null;
    this.startDeliveryDate = order?.startDeliveryDate ?? null;
    this.state = order?.state ?? 0;
    this.invoiceNumber = order?.invoiceNumber ?? '';
    this.printDate = order?.printDate ?? null;
    this.prodPlant = order?.prodPlant ?? '';
    this.qualityErrors = (order?.qualityErrors ?? []).map(error => new QualityError(error));
    this.lockDate = order?.lockDate ?? null;
    this.organizationId = order?.organizationId ?? '';
    this.source = order?.source ?? SourceType.OKTO;
  }

  /**
   * Converter used to convert a user from firebase
   */
  static converter: FirestoreDataConverter<Order> = {
    toFirestore: (order: Order) => ({
      id: order.id,
      products: ObjectHelper.anonymize(order.products),
      userId: order.userId,
      webOrderId: order.webOrderId,
      name: order.name,
      managerId: order.managerId,
      salesId: order.salesId,
      paid: order.paid,
      canceled: order.canceled,
      amount: order.amount,
      reference: order.reference,
      transactionId: order.transactionId,
      emailIsActive: order.emailIsActive,
      batIsActive: order.batIsActive,
      fileCheckError: order.fileCheckError,
      fileCheckErrorCode: order.fileCheckErrorCode,
      batVersion: order.batVersion,
      new: order.new,
      emailFile: order.emailFile,
      print: order.print,
      archived: order.archived,
      printNumber: order.printNumber,
      disabled: order.disabled,
      lockComment: order.lockComment,
      lockedBy: order.lockedBy,
      lockerName: order.lockerName,
      contactName: order.contactName,
      managerEmail: order.managerEmail,
      salesEmail: order.salesEmail,
      managerName: order.managerName,
      salesName: order.salesName,
      customerEmails: order.customerEmails,
      customerId: order.customerId,
      company: order.company,
      emailBat: order.emailBat,
      emailInvoice: order.emailInvoice,
      emailOrder: order.emailOrder,
      batMailSent: order.batMailSent,
      mailDate: order.mailDate,
      date: order.date,
      batDate: order.batDate,
      uploadsDate: order.uploadsDate,
      processDate: order.processDate,
      startDeliveryDate: order.startDeliveryDate,
      state: order.state,
      invoiceNumber: order.invoiceNumber,
      printDate: order.printDate,
      prodPlant: order.prodPlant,
      qualityErrors: ObjectHelper.anonymize(order.qualityErrors),
      lockDate: order.lockDate,
      organizationId: order.organizationId,
      source: order.source,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Order({
        ...data,
        id: snapshot.id,
        date: data.date ? new Date(data.date.seconds * 1000) : null,
        uploadsDate: data.uploadsDate ? new Date(data.uploadsDate.seconds * 1000) : null,
        batDate: data.batDate ? new Date(data.batDate.seconds * 1000) : null,
        mailDate: data.mailDate ? new Date(data.mailDate.seconds * 1000) : null,
        printDate: data.printDate ? new Date(data.printDate.seconds * 1000) : null,
        products: (data.products ?? []).map(product => ({
          ...product,
          deliveryDate: product.deliveryDate ? new Date(product.deliveryDate.seconds * 1000) : null
        })),
        qualityErrors: (data.qualityErrors ?? []).map(error => ({
          ...error,
          date: error.date ? new Date(error.date.seconds * 1000) : null
        })),
        startDeliveryDate: data?.products?.length ?
          new Date((data.products.find(product => product.deliveryDate)?.deliveryDate.seconds ?? 0) * 1000) :
          data?.startDeliveryDate?.seconds ? new Date(data.startDeliveryDate.seconds * 1000) : null,
        lockDate: data.lockDate ? new Date(data.lockDate.seconds * 1000) : null,
        customerId: String(data.customerId)
      });
    }
  }
}
