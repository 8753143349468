import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type DrillingForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  description: FormControl<string>;
  distance: FormControl<number>;
  amount: FormControl<number>;
  eyelet: FormControl<boolean>;
  organizationId: FormControl<string>;
}>

export class Drilling {
  id: string;
  erpId: string;
  description: string;
  distance: number;
  amount: number;
  eyelet: boolean;
  organizationId: string;

  constructor(data?: Partial<Drilling>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.distance = data?.distance ?? 0;
    this.amount = data?.amount ?? 0;
    this.eyelet = data?.eyelet ?? false;
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(drilling: Drilling) {
    return {
      id: drilling.id,
      erpId: drilling.erpId,
      description: drilling.description,
      distance: drilling.distance,
      amount: drilling.amount,
      eyelet: drilling.eyelet,
      organizationId: drilling.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Drilling(snapToData(snapshot, {idField: 'id'}));
  }
}
