import { FirestoreDataConverter } from '@angular/fire/firestore';
import { Settings } from './settings.base';
import { SettingsEnum } from '../enums/settings.enum';
import { ObjectHelper } from "../helpers/object.helper";
import { Smtp } from './smtp.model';

export class OktoSettings extends Settings {
  converter: FirestoreDataConverter<OktoSettings> = {
    toFirestore: (settings: OktoSettings) => {
      const obj = ObjectHelper.anonymize(settings);
      delete obj.converter;
      delete obj.type;
      delete obj.id;
      return obj;
    },
    fromFirestore: (snapshot, options) =>
      new OktoSettings({ ...snapshot.data(options), id: snapshot.id })
  };
  type = SettingsEnum.OKTO;
  deliverySetting: DeliverySetting;
  commerce: CommerceSettings;
  smtp: Omit<Smtp, 'id'>;

  constructor(data?: Partial<OktoSettings>) {
    super(data);
    this.deliverySetting = new DeliverySetting(data?.deliverySetting);
    this.commerce = new CommerceSettings(data?.commerce);
    this.smtp = {
      port: data?.smtp?.port ?? 0,
      host: data?.smtp?.host ?? '',
      password: data?.smtp?.password ?? '',
      sender: data?.smtp?.sender ?? '',
      user: data?.smtp?.user ?? ''
    }
  }
}

export class CommerceSettings {
  installationsCommission: number;
  externalProductionsCommission: number;
  constructor(data?: Partial<CommerceSettings>) {
    this.installationsCommission = data?.installationsCommission ?? 0;
    this.externalProductionsCommission = data?.externalProductionsCommission ?? 0;
  }
}

export class DeliverySetting {
  defaultPrePressNotifications: boolean;
  defaultQuotationsNotifications: boolean;
  defaultInvoicesNotifications: boolean;
  defaultDeliveryTime: number;
  notifyFile1: number;
  notifyFile2: number;
  notifyFile3: number;
  notifyProof1: number;
  notifyProof2: number;
  notifyProof3: number;
  notifyInvoice1: number;
  notifyInvoice2: number;
  notifyInvoice3: number;
  notifyQuotation1: number;
  notifyQuotation2: number;
  notifyQuotation3: number;

  constructor(data?: Partial<DeliverySetting>) {
    this.defaultDeliveryTime = data?.defaultDeliveryTime ?? 5;
    this.defaultPrePressNotifications = data?.defaultPrePressNotifications ?? false;
    this.defaultQuotationsNotifications = data?.defaultQuotationsNotifications ?? false;
    this.defaultInvoicesNotifications = data?.defaultInvoicesNotifications ?? false;
    this.notifyFile1 = data?.notifyFile1 ?? 6;
    this.notifyFile2 = data?.notifyFile2 ?? 3;
    this.notifyFile3 = data?.notifyFile3 ?? 1;
    this.notifyProof1 = data?.notifyProof1 ?? 6;
    this.notifyProof2 = data?.notifyProof2 ?? 3;
    this.notifyProof3 = data?.notifyProof3 ?? 1;
    this.notifyInvoice1 = data?.notifyInvoice1 ?? 7;
    this.notifyInvoice2 = data?.notifyInvoice2 ?? 15;
    this.notifyInvoice3 = data?.notifyInvoice3 ?? 30;
    this.notifyQuotation1 = data?.notifyQuotation1 ?? 7;
    this.notifyQuotation2 = data?.notifyQuotation2 ?? 15;
    this.notifyQuotation3 = data?.notifyQuotation3 ?? 30;
  }
}
