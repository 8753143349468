import { ProductOrderFile } from './product-order-file.model';
import { UUID } from "angular2-uuid";
import { Format } from "./format.model";
import { Material } from "./material.model";
import { Printing } from "./printing.model";
import { Finish } from "./finish.model";
import { Cutting } from "./cutting.model";
import { Drilling } from "./drilling.model";
import { Binding } from "./binding.model";
import { Folding } from "./folding.model";

/**
 * Model for the products prices
 */
export class ProductPrice {
  quantity: number;
  price: number;
  index: number;
}

/**
 * Model for the products prices
 */
export class QuantityDispatch {
  quantity: number;
  index: number;
}

/**
 * Model for the products of an order
 */
export class ProductOrder {
  poId: string;
  productId: string;
  productName: string;
  quantity: number;
  isVersion: boolean;
  comment: string;
  batValid?: boolean;
  batReason: string;
  deliveryDate?: Date;
  profile: string;
  /** @deprecated (replaced by dimension)*/
  format: string;
  newFile: { path: string, name: string } | null;
  version: string;
  poFiles: ProductOrderFile[];
  webProduct: string;
  description: string;
  fileRequirement: string;
  dimension: Format;
  support: Material;
  printType: Printing;
  finishing: Finish;
  cutting: Cutting;
  drilling: Drilling;
  binding: Binding;
  folding: Folding;
  productPrice: number;
  prices: ProductPrice[];
  dispatch: QuantityDispatch[];
  conditioning: string;

  constructor(product?: Partial<ProductOrder>) {
    this.poId = product?.poId ?? UUID.UUID();
    this.productName = product?.productName ?? '';
    this.productId = product?.productId ?? '';
    this.quantity = product?.quantity ?? 0;
    this.isVersion = product?.isVersion ?? false;
    this.comment = product?.comment ?? '';
    this.batValid = product?.batValid ?? null;
    this.batReason = product?.batReason ?? '';
    this.version = product?.version ?? '';
    this.deliveryDate = product?.deliveryDate ?? null;
    this.profile = product?.profile ?? '';
    this.format = product?.format ?? '';
    this.newFile = product?.newFile ?? null;
    this.poFiles = (product?.poFiles ?? []).map(poFile => new ProductOrderFile(poFile));
    this.description = product?.description ?? '';
    this.fileRequirement = product?.fileRequirement ?? '';
    this.dimension = product?.dimension ?? null;
    this.support = product?.support ?? null;
    this.printType = product?.printType ?? null;
    this.finishing = product?.finishing ?? null;
    this.cutting = product?.cutting ?? null;
    this.drilling = product?.drilling ?? null;
    this.binding = product?.binding ?? null;
    this.folding = product?.folding ?? null;
    this.webProduct = product?.webProduct ?? null;
    this.productPrice = product?.productPrice ?? -1;
    this.prices = product?.prices ?? [];
    this.dispatch = product?.dispatch ?? [];
    this.conditioning = product?.conditioning ?? '';
  }
}
