<main class="h-full flex flex-col">
  <mat-toolbar class="mat-elevation-z0 z-50" color="primary">
    <button mat-icon-button (click)="menuOpened = !menuOpened"
            *ngIf="menuDisplayed()">
      <mat-icon *ngIf="!menuOpened">menu</mat-icon>
      <mat-icon *ngIf="menuOpened">menu_open</mat-icon>
    </button>
    <div class="cursor-pointer flex flex-row">
      <img ngSrc="./assets/images/white_icon_transparent_background.png" class="okto-icon"
           alt="Okto logo" height="32" width="32">
      <span class="font-light invisible w-0 sm:visible sm:w-full">Okto Cloud</span>
    </div>
    <ng-container *ngIf="authService.isLoggedIn && organizationName">
      <button mat-stroked-button [matMenuTriggerFor]="organizationMenu" class="ml-4 border-1 border-white">
        {{ organizationName }}
        <mat-icon matSuffix>expand_more</mat-icon>
      </button>
      <mat-menu #organizationMenu="matMenu" class="rounded-xl min-w-64">
        <button mat-menu-item routerLink="/">
          {{'organizations' | translate}}
        </button>
        <mat-divider></mat-divider>
        <button *ngFor="let organization of userOrganizations"
                mat-menu-item (click)="goToOrganization(organization)">
          {{ organizationNames[organization.id] }}
        </button>
      </mat-menu>
    </ng-container>
    <span class="grow"></span>
    <app-menu [options]="options$ | async" (themeChange)="themeChangeHandler($event)"></app-menu>
    <ng-container *ngIf="authService.isLoggedIn">
      <mat-menu #profileMenu="matMenu" class="rounded-xl">
        <app-user-profile></app-user-profile>
      </mat-menu>
      <div class="ml-2 cursor-pointer" [matMenuTriggerFor]="profileMenu">
        <img *ngIf="authService.currentUser.photoURL" src="{{authService.currentUser.photoURL}}"
             class="rounded-full h-8 w-8 object-contain" alt="user profile image">
        <div *ngIf="!authService.currentUser.photoURL && authService.currentUser.displayName"
             class="font-bold text-gray-700 rounded-full bg-white flex items-center justify-center text-md h-8 w-8">
          {{ authService.currentUser.displayName.substring(0, 1) }}
        </div>
      </div>
    </ng-container>
  </mat-toolbar>
  <mat-sidenav-container class="h-full">
    <mat-sidenav [mode]="smallLayout ? 'over' : 'side'" class="w-72 pb-16"
                 [opened]="menuOpened"
                 [ngClass]="{ 'closed-sidenav' : !menuOpened && !smallLayout}"
                 *ngIf="menuDisplayed()">
      <ng-container *ngIf="menuOpened; else icons">
        <mat-form-field class="form-field-compact main-search-bar" (keyup.enter)="search()"
                        *ngIf="menuOpened && (authService.currentUser.roles.length || authService.isOrganizationAdmin)">
          <mat-label>{{ 'order.search.label' | translate }}</mat-label>
          <input matInput type="text" autocomplete="off" data-lpignore="true" data-form-type="other"
                 [(ngModel)]="searchedValue"/>
          <button mat-icon-button matSuffix (click)="search()">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </ng-container>
      <ng-template #icons>
        <mat-nav-list>
          <mat-list-item *ngIf="authService.currentUser.roles.length || authService.isOrganizationAdmin"
            (click)="menuOpened = !menuOpened" class="admin-menu-list">
            <mat-icon matListItemIcon class="sub-menu-icon">search</mat-icon>
          </mat-list-item>
        </mat-nav-list>
      </ng-template>
      <!--Dashboard-->
      <ng-container>
        <h2 class="menu-title" *ngIf="menuOpened">{{ 'myAccount'| translate }}</h2>
        <mat-divider></mat-divider>
        <mat-nav-list>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'dashboard']"
                         (click)="goToAction()">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">dashboard</mat-icon>
            <div matListItemTitle *ngIf="menuOpened">{{ 'dashboard' | translate }}</div>
          </mat-list-item>
          <mat-list-item [routerLink]="['/', organizationService.currentOrganization.oid, 'dashboard', 'quotations']"
                         (click)="goToAction()">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">request_quote</mat-icon>
            <div matListItemTitle *ngIf="menuOpened">{{ 'myQuotations' | translate }}</div>
          </mat-list-item>
          <mat-list-item [routerLink]="['/', organizationService.currentOrganization.oid, 'dashboard', 'orders']"
                         class="admin-menu-list" (click)="goToAction()">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">shopping_bag</mat-icon>
            <span *ngIf="menuOpened">{{ 'myOrders' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="['/', organizationService.currentOrganization.oid, 'dashboard', 'installations']"
                         class="admin-menu-list" *ngIf="authService.hasRole(RolesEnum.INSTALLATION_WRITE)"
                         (click)="goToAction()">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">developer_board</mat-icon>
            <span *ngIf="menuOpened">{{ 'myInstallations' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="['/', organizationService.currentOrganization.oid, 'dashboard', 'external-productions']"
                         class="admin-menu-list" *ngIf="authService.hasRole(RolesEnum.EXTERNAL_PRODUCTION_WRITE)"
                         (click)="goToAction()">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">hub</mat-icon>
            <span *ngIf="menuOpened">{{ 'myExternalProduction' | translate }}</span>
          </mat-list-item>
        </mat-nav-list>
      </ng-container>
      <ng-container *ngIf="organizationService.currentOrganization.plan === PlanEnum.PAY_AS_YOU_GO">
        <!--Commerce-->
        <ng-container
          *ngIf="authService.hasRole([RolesEnum.CUSTOMER_WRITE, RolesEnum.COMMERCE_WRITE, RolesEnum.QUOTATION_WRITE,
                                           RolesEnum.PRODUCT_WRITE, RolesEnum.ORDER_READ])">
          <h2 class="menu-title" *ngIf="menuOpened">{{ 'business.adminMenu.title'| translate }}</h2>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'quotations']"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.QUOTATION_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">request_quote</mat-icon>
              <div matListItemTitle *ngIf="menuOpened">{{ 'ADMIN.QUOTATIONS' | translate }}</div>
            </mat-list-item>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'account']"
                           class="admin-menu-list" (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.ORDER_READ)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">shopping_bag</mat-icon>
              <span *ngIf="menuOpened">{{ 'ordersTracking' | translate }}</span>
            </mat-list-item>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'customers']"
                           class="admin-menu-list"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.CUSTOMER_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">groups</mat-icon>
              <span *ngIf="menuOpened">{{ 'business.menu.title' | translate }}</span>
            </mat-list-item>
            <ng-container *ngIf="authService.hasRole(RolesEnum.PRODUCT_WRITE)">
              <a mat-list-item
                 [routerLink]="[organizationService.currentOrganization.oid, 'products']">
                <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">category</mat-icon>
                <span *ngIf="menuOpened">{{ 'products.menu.title' | translate }}</span>
              </a>
            </ng-container>
            <ng-container *ngIf="authService.hasRole(RolesEnum.COMMERCE_WRITE)">
              <a mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'shops']">
                <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">store</mat-icon>
                <span *ngIf="menuOpened">{{ 'shops.menu.title' | translate }}</span>
              </a>
            </ng-container>
          </mat-nav-list>
        </ng-container>
        <!--Pre-press-->
        <div *ngIf="authService.hasRole([RolesEnum.ORDER_WRITE, RolesEnum.SCRIPT_WRITE])">
          <h2 class="menu-title" *ngIf="menuOpened">{{ 'ADMIN.PRODUCTION' | translate }}</h2>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'orders']"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.ORDER_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">design_services</mat-icon>
              <div matListItemTitle *ngIf="menuOpened">{{ 'ADMIN.ORDERS' | translate }}</div>
            </mat-list-item>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'scripts']"
                           class="admin-menu-list"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.SCRIPT_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">integration_instructions</mat-icon>
              <span *ngIf="menuOpened">{{ 'scripts' | translate }}</span>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <!--Production-->
        <div *ngIf="authService.hasRole([RolesEnum.PRODUCTION_WRITE, RolesEnum.MACHINE_WRITE])">
          <h2 class="menu-title" *ngIf="menuOpened">{{ 'settings.okto.production.title' | translate }}</h2>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'productions']"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.PRODUCTION_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">construction</mat-icon>
              <div matListItemTitle *ngIf="menuOpened">{{ 'manufacturing' | translate }}</div>
            </mat-list-item>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'machines']"
                           class="admin-menu-list"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.MACHINE_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">dynamic_form</mat-icon>
              <span *ngIf="menuOpened">{{ 'machines' | translate }}</span>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <!--Subcontracting-->
        <div *ngIf="authService.hasRole([RolesEnum.INSTALLATION_WRITE, RolesEnum.EXTERNAL_PRODUCTION_WRITE])">
          <h2 class="menu-title" *ngIf="menuOpened">{{ 'subcontracting'| translate }}</h2>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'installations']"
                           class="admin-menu-list"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.INSTALLATION_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">developer_board</mat-icon>
              <span *ngIf="menuOpened">{{ 'followInstallation' | translate }}</span>
            </mat-list-item>
            <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'external-productions']"
                           class="admin-menu-list"
                           (click)="goToAction()"
                           *ngIf="authService.hasRole(RolesEnum.EXTERNAL_PRODUCTION_WRITE)">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">hub</mat-icon>
              <span *ngIf="menuOpened">{{ 'externalProductions' | translate }}</span>
            </mat-list-item>
          </mat-nav-list>
        </div>
      </ng-container>
      <!--Transport-->
      <div *ngIf="authService.hasRole([RolesEnum.SHIPMENT_READ, RolesEnum.WORKSHOP_WRITE])">
        <h2 class="menu-title" *ngIf="menuOpened">{{ 'shipment.adminMenu.title'| translate }}</h2>
        <mat-divider></mat-divider>
        <mat-nav-list>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'shipments']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.SHIPMENT_READ)">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">local_shipping</mat-icon>
            <span *ngIf="menuOpened">{{ 'shipment.menu.title' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'closing-day']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.SHIPMENT_READ)">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">event_available</mat-icon>
            <span *ngIf="menuOpened">{{ 'closingDay' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'workshop']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.WORKSHOP_WRITE)">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">add_location</mat-icon>
            <span *ngIf="menuOpened">{{ 'shipment.workshopMenu.title' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'pallet']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.WORKSHOP_WRITE)">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">clear_all</mat-icon>
            <span *ngIf="menuOpened">{{ 'shipment.palletMenu.title' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'transport-calculator']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.SHIPMENT_READ) &&
                         organizationService.currentOrganization.plan === PlanEnum.PAY_AS_YOU_GO">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">calculate</mat-icon>
            <span *ngIf="menuOpened">{{ 'shipment.calc.title' | translate }}</span>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <!--Settings-->
      <div *ngIf="authService.hasRole([RolesEnum.SETTING_WRITE, RolesEnum.USER_WRITE, RolesEnum.DATABASE_WRITE, RolesEnum.CONFIGURATION_WRITE])">
        <h2 class="menu-title" *ngIf="menuOpened">{{ 'settings.main.title'| translate }}</h2>
        <mat-divider></mat-divider>
        <mat-nav-list>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'database']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.DATABASE_WRITE) &&
                         organizationService.currentOrganization.plan === PlanEnum.PAY_AS_YOU_GO">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">storage</mat-icon>
            <span *ngIf="menuOpened">{{ 'database' | translate }}</span>
          </mat-list-item>
          <ng-container *ngIf="authService.hasRole(RolesEnum.CONFIGURATION_WRITE) &&
          organizationService.currentOrganization.plan === PlanEnum.PAY_AS_YOU_GO">
            <a mat-list-item
               [routerLink]="[organizationService.currentOrganization.oid, 'configurations']">
              <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">settings_suggest</mat-icon>
              <span *ngIf="menuOpened">{{ 'configurations.menu.title' | translate }}</span>
            </a>
          </ng-container>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'users']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.isOrganizationAdmin">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">admin_panel_settings</mat-icon>
            <span *ngIf="menuOpened">{{ 'ADMIN.ADMIN_USERS' | translate }}</span>
          </mat-list-item>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'settings']"
                         class="admin-menu-list"
                         (click)="goToAction()"
                         *ngIf="authService.isOrganizationAdmin">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">settings</mat-icon>
            <span *ngIf="menuOpened">{{ 'settings.subMenu.title' | translate }}</span>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <!--Plugins-->
        <div *ngIf="organizationService.currentOrganization.plugins.length && authService.hasRole(RolesEnum.IMPORT_WRITE)">
        <h2 class="menu-title" *ngIf="menuOpened">{{ 'plugins' | translate }}</h2>
        <mat-divider></mat-divider>
        <mat-nav-list>
          <mat-list-item [routerLink]="[organizationService.currentOrganization.oid, 'imports']"
                         class="admin-menu-list" (click)="goToAction()"
                         *ngIf="authService.hasRole(RolesEnum.IMPORT_WRITE) &&
                         organizationService.currentOrganization.plugins.includes('printoclock')">
            <mat-icon matListItemIcon [ngClass]="{'sub-menu-icon': !menuOpened}">alarm</mat-icon>
            <span *ngIf="menuOpened">{{ 'ADMIN.IMPORTS' | translate }}</span>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <div class="bg-transparent w-full absolute h-16 bottom-0">
        <div class="font-bold h-full flex flex-row justify-center items-center border-t-2">
          {{ 'version' | translate }} : {{ version }}
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content [ngClass]="{
    'admin-app-desktop-small' : menuDisplayed() && !menuOpened && !smallLayout,
    'px-4 lg:px-8 py-4 h-full': authService.isLoggedIn}">
      <div class="app-spinner" *ngIf="dataService.dataLoading">
        <mat-spinner mode="indeterminate" diameter="70"></mat-spinner>
      </div>
      <div class="h-full w-full">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
