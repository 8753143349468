import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type FinishForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  description: FormControl<string>;
  value: FormControl<FinishEnum>;
  organizationId: FormControl<string>;
}>

export class Finish {
  id: string;
  erpId: string;
  description: string;
  value: FinishEnum;
  organizationId: string;

  constructor(data?: Partial<Finish>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.value = data?.value ?? FinishEnum.NONE;
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(finish: Finish) {
    return {
      id: finish.id,
      erpId: finish.erpId,
      description: finish.description,
      value: finish.value,
      organizationId: finish.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Finish(snapToData(snapshot, {idField: 'id'}));
  }
}

export enum FinishEnum {
  NONE = 'none',
  GLOSSY = 'glossy',
  MATE = 'mate',
  GRAFFITI = 'graffiti',
  SELECTIVE = 'selective',
  RAW = 'raw',
  HEMS = 'hems',
  CHANNELS = 'channels',
}
