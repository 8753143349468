import { FormControl, FormGroup } from '@angular/forms';
import { FileTypeEnum } from "../enums/file-type.enum";

export type DocumentModelForm = FormGroup<{
  path: FormControl<string>;
  url: FormControl<string>;
  name: FormControl<string>;
  type: FormControl<FileTypeEnum>;
}>

export class DocumentModel {
  public path: string;
  public url: string;
  public name: string;
  public type: FileTypeEnum;

  constructor(data?: Partial<DocumentModel>) {
    this.path = data?.path ?? '';
    this.name = data?.name ?? '';
    this.type = data?.type ?? FileTypeEnum.IMAGE;
  }
}
