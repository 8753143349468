import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type CuttingForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  description: FormControl<string>;
  type: FormControl<CuttingEnum>;
  organizationId: FormControl<string>;
}>

export class Cutting {
  id: string;
  erpId: string;
  description: string;
  type: CuttingEnum;
  organizationId: string;

  constructor(data?: Partial<Cutting>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.type = data?.type ?? CuttingEnum.STRAIGHT;
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(cutting: Cutting) {
    return {
      id: cutting.id,
      erpId: cutting.erpId,
      description: cutting.description,
      type: cutting.type,
      organizationId: cutting.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Cutting(snapToData(snapshot, {idField: 'id'}));
  }
}

export enum CuttingEnum {
  STRAIGHT = 'straight',
  TO_SHAPE = 'shape',
}
