import { ImageModel, ImageModelForm } from './image.model';
import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type FormatForm = FormGroup<{
  id: FormControl<string>;
  custom: FormControl<boolean>;
  widthMin: FormControl<number>;
  widthMax: FormControl<number>;
  heightMin: FormControl<number>;
  heightMax: FormControl<number>;
  depthMin: FormControl<number>;
  depthMax: FormControl<number>;
  description: FormControl<string>;
  width: FormControl<number>;
  height: FormControl<number>;
  depth: FormControl<number>;
  gabarit: ImageModelForm;
  organizationId: FormControl<string>;
}>


export class Format {
  id: string;
  custom: boolean;
  description: string;

  // custom = true
  widthMin: number;
  widthMax: number;
  heightMin: number;
  heightMax: number;
  depthMin: number;
  depthMax: number;

  // custom = false
  width: number;
  height: number;
  depth: number;
  gabarit: ImageModel | null;
  organizationId: string;

  constructor(data?: Partial<Format>) {
    this.id = data?.id ?? '';
    this.custom = data?.custom ?? false;
    this.widthMin = data?.widthMin ?? 0;
    this.widthMax = data?.widthMax ?? 0;
    this.heightMin = data?.heightMin ?? 0;
    this.heightMax = data?.heightMax ?? 0;
    this.depthMin = data?.depthMin ?? 0;
    this.depthMax = data?.depthMax ?? 0;
    this.description = data?.description ?? '';
    this.width = data?.width ?? 0;
    this.height = data?.height ?? 0;
    this.depth = data?.depth ?? 0;
    this.gabarit = data?.gabarit ?? null;
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(format: Format) {
    return {
      id: format.id,
      custom: format.custom,
      description: format.description,
      widthMin: format.widthMin,
      widthMax: format.widthMax,
      heightMin: format.heightMin,
      heightMax: format.heightMax,
      depthMin: format.depthMin,
      depthMax: format.depthMax,
      width: format.width,
      height: format.height,
      depth: format.depth,
      gabarit: format.gabarit,
      organizationId: format.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Format(snapToData(snapshot, { idField: 'id' }));
  }
}
