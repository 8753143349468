import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  or,
  setDoc,
  Unsubscribe,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommerceVariation } from '../models/commerce-variation.model';
import { CommerceCategory } from '../models/commerce-category.model';
import { filter } from 'rxjs/operators';
import { CommercePriceGrid } from '../models/commerce-price-grid.model';
import {
  OptionConverters,
  OptionObject,
  OptionTypeToVariationFields
} from '../models/commerce-configuration-option.model';
import { ObjectHelper } from '../helpers/object.helper';
import { WebProduct } from '../models/web-product.model';
import { Format } from '../models/format.model';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  private firestore = inject(Firestore);
  private functions = inject(Functions);
  private storageService = inject(UploadService);

  private categories$: Record<string, BehaviorSubject<CommerceCategory[]>> = {};
  private unsubscribeCategories: Unsubscribe;
  private options$: [string, BehaviorSubject<{ [type in keyof OptionObject]: OptionObject[type][] }>];
  private optionsInitialized: { [type: string]: boolean };
  private unsubscribeOptions: Unsubscribe[] = [];

  /**
   * Update the configuration option of a given type
   * @param type The option type
   * @param option The option object
   * @returns Firebase object id
   */
  updateConfigurationOption<T extends keyof OptionObject>(type: T, option: OptionObject[T]): Promise<string> {
    const col = collection(this.firestore, type);
    const ref = (option.id ? doc(col, option.id) : doc(col))
      .withConverter(OptionConverters[type]);
    return setDoc(ref, option, { merge: true })
      .then(() => ref.id);
  }

  /**
   * Delete the configuration option of a given type
   * @param type The option type
   * @param option The option object
   * @returns Firebase object id
   */
  async configurationsByOption<T extends keyof OptionObject>(type: T, option: OptionObject[T]):
    Promise<CommerceVariation[]> {
    const q = query(
      collection(this.firestore, 'variation'),
      or(...OptionTypeToVariationFields[type].map(field => where(field, '==', option.id)))
    ).withConverter(CommerceVariation.converter);

    const snap = await getDocs(q);
    if (snap.empty) {
      return [];
    }
    return snap.docs.map(doc => doc.data())
  }


  /**
   * Delete the configuration option of a given type
   * @param type The option type
   * @param option The option object
   * @returns Firebase object id
   */
  async deleteConfigurationOption<T extends keyof OptionObject>(type: T, option: OptionObject[T]): Promise<void> {
    if (option instanceof Format && option.gabarit.path) {
      await this.storageService.removeFile(option.gabarit.path)
    }
    const col = collection(this.firestore, type);
    const ref = (option.id ? doc(col, option.id) : doc(col))
      .withConverter(OptionConverters[type]);
    await deleteDoc(ref);
  }

  /**
   * Get all configuration options of a given type
   * @param organizationId organizationId
   * @returns An observable of configuration options
   */
  configurationOptions(organizationId: string): Observable<{ [type in keyof OptionObject]: OptionObject[type][] }> {
    const options = Object.keys(OptionConverters)
    if (this.options$?.[0] !== organizationId) {
      this.unsubscribeOptions.forEach(unsub => unsub());
      this.optionsInitialized = options.reduce((obj, o) => ({ ...obj, [o]: false }), {})
      this.options$ = [organizationId, new BehaviorSubject<{ [type in keyof OptionObject]: OptionObject[type][] }>({
        formats: [],
        materials: [],
        printTypes: [],
        finishes: [],
        cuttings: [],
        drillings: [],
        bindings: [],
        foldings: [],
      })];
      this.unsubscribeOptions = options
        .map(type =>
          onSnapshot(
            query(
              collection(this.firestore, type),
              where('organizationId', '==', organizationId)
            ).withConverter(OptionConverters[type]),
            snapshot => {
              this.optionsInitialized[type] = true;
              if (snapshot.empty) {
                this.options$[1].next({ ...this.options$[1].value, [type]: [] })
              }
              this.options$[1].next({ ...this.options$[1].value, [type]: snapshot.docs.map(doc => doc.data()) })
            }
          )
        );
    }
    return this.options$[1]
      .pipe(filter(() => Object.values(this.optionsInitialized).every(o => o)));
  }

  /**
   * Get variations for the given configuration, calling the onNext callback on PubSub event
   * @param organizationId organizationId
   * @param onNext The result callback
   * @returns Subscribe event
   */
  variations(organizationId: string, onNext: (variations: CommerceVariation[]) => void)
    : Unsubscribe {
    const q = query(
      collection(this.firestore, 'variation'),
      where('organizationId', '==', organizationId)
    )
      .withConverter(CommerceVariation.converter);
    return onSnapshot(q, snapshot => {
      if (snapshot.empty) {
        return onNext([]);
      }
      return onNext(snapshot.docs.map(doc => doc.data()));
    });
  }

  /**
   * Get the variation of the given id
   * @param id The variation id
   * @returns The variation
   */
  variation(id: string): Promise<CommerceVariation> {
    const ref = doc(this.firestore, 'variation', id)
      .withConverter(CommerceVariation.converter);
    return getDoc(ref).then(doc => doc.data());
  }

  /**
   * Update the variation
   * @param variation The variation to update
   * @returns void
   */
  updateVariation(variation: Partial<CommerceVariation>): Promise<void> {
    const ref = doc(this.firestore, 'variation', variation.id)
      .withConverter(CommerceVariation.converter);
    return updateDoc(ref, ObjectHelper.anonymize(variation));
  }

  /**
   * Create new variation
   * @param variation The variation to update
   * @returns The created variation id
   */
  addVariation(variation: Partial<CommerceVariation>): Promise<string> {
    const ref = collection(this.firestore, 'variation')
      .withConverter(CommerceVariation.converter);
    return addDoc(ref, ObjectHelper.anonymize(variation)).then(ref => ref.id);
  }

  /**
   * Delete a variation and its price grids
   * @param variationId The variation to delete;
   */
  async delete(variationId: string): Promise<void> {
    const ref = doc(collection(this.firestore, 'variation'), variationId);
    await deleteDoc(ref);
    const snap = await getDocs(query(
      collection(this.firestore, 'price-grid'),
      where('variationId', '==', variationId)
    ));
    if (!snap.empty) {
      for (const doc of snap.docs) {
        await deleteDoc(doc.ref);
      }
    }
  }

  /**
   * Get all the products
   * @param shop The shop
   * @returns The observable
   */
  categories(shop: string): Observable<CommerceCategory[]> {
    if (!this.categories$[shop]) {
      this.categories$[shop] = new BehaviorSubject<CommerceCategory[]>([]);
      const q = query(
        collection(this.firestore, 'category'),
        where('shop', '==', shop)
      )
        .withConverter(CommerceCategory.converter);
      this.unsubscribeCategories = onSnapshot(q, snapshot => {
        if (snapshot.empty) {
          return this.categories$[shop].next([]);
        }
        this.categories$[shop].next(snapshot.docs.map(doc => doc.data()));
      });
    }
    return this.categories$[shop];
  }

  /**
   * Create a new category
   * @param shop The shop
   * @param parent The category parent
   */
  async addCategory(shop: string, parent: string): Promise<void> {
    const ref = collection(this.firestore, 'category')
      .withConverter(CommerceCategory.converter);
    await addDoc(ref, new CommerceCategory({ shop, parent }));
  }

  /**
   * Update the given category
   * @param category The category
   * @returns Update doc promise
   */
  updateCategory(category: Partial<CommerceCategory>): Promise<void> {
    const ref = doc(this.firestore, 'category', category.id)
      .withConverter(CommerceCategory.converter);
    return updateDoc(ref, {
      name: category.name,
      description: category.description,
      icon: category.icon,
      image: {
        name: category.image.name,
        description: category.image.description,
        path: category.image.path,
        thumb: category.image.thumb,
      }
    });
  }

  /**
   * Delete the category with the given id
   * @param id Id of the category to delete
   */
  async deleteCategory(id: string): Promise<void> {
    const ref = doc(this.firestore, 'category', id)
      .withConverter(CommerceCategory.converter);
    await deleteDoc(ref);
  }

  /**
   * Get the price grids for these variations, on PubSub event the callback is called
   * @param variation The variation id
   * @param onNext Next variations callback
   * @returns The Unsubscribe function
   */
  priceGrids(variation: string, onNext: (variations: CommercePriceGrid[]) => void | Promise<void>): Unsubscribe {
    const q = query(
      collection(this.firestore, 'price-grid'),
      where('variationId', '==', variation)
    )
      .withConverter(CommercePriceGrid.converter);
    return onSnapshot(q, snapshot => {
      onNext(snapshot.empty ? [] : snapshot.docs.map(doc => doc.data()));
    });
  }

  /**
   * Generate the price grids for the given variations and quantities, and if necessary
   * creating specific grid with specific margin for specific customers
   * @param variationId The variation id
   * @param quantities List of required quantities
   * @param customer The customer id
   * @returns The calculate prices foreach quantities
   */
  async getPriceGrid(variationId: string, quantities: number[], customer: string): Promise<{
    from: number,
    price: number
  }[]> {
    return httpsCallable<{ variationId: string, quantities: number[], customer: string },
      { from: number, price: number }[]>(this.functions, 'get_price_grid')(
        { variationId, quantities, customer })
      .then(result => result.data);
  }

  /**
   * Create a price grid
   * @param priceGrid The price grid object to create
   */
  async addPriceGrid(priceGrid: CommercePriceGrid): Promise<void> {
    const ref = collection(this.firestore, 'price-grid')
      .withConverter(CommercePriceGrid.converter);
    await addDoc(ref, priceGrid);
  }

  /**
   * Update the price grid
   * @param priceGrid The price grid id to update
   * @returns Update doc promise
   */
  async updateGrid(priceGrid: CommercePriceGrid): Promise<void> {
    const ref = doc(this.firestore, 'price-grid', priceGrid.id)
      .withConverter(CommercePriceGrid.converter);
    return updateDoc(ref, {
      name: priceGrid.name,
      prices: priceGrid.prices,
      autoRefresh: priceGrid.autoRefresh,
      customers: priceGrid.customers,
      quantityMin: Number.isInteger(priceGrid.quantityMin) ? Number(priceGrid.quantityMin) : 0,
      quantityMax: Number.isInteger(priceGrid.quantityMax) ? Number(priceGrid.quantityMax) : 0,
      fixedCost: Number.isNaN(priceGrid.fixedCost) ? 0 : Number(priceGrid.fixedCost),
    });
  }

  /**
   * Delete the price grid
   * @param id Price grid id
   */
  async deletePriceGrid(id: string): Promise<void> {
    const ref = doc(this.firestore, 'price-grid', id)
      .withConverter(CommercePriceGrid.converter);
    await deleteDoc(ref);
  }

  /**
   * Count the number sales of a product
   * @param productId The product id
   * @returns Total sales of the product
   */
  async countSoldWebProducts(productId: string): Promise<number> {
    const q = query(
      collection(this.firestore, 'web-products'),
      where('product', '==', productId)
    ).withConverter(WebProduct.converter);
    const ref = await getDocs(q);
    ref.docs.filter(product => product.data().transactionId != '');
    return ref.docs.length;
  }

  /**
   * Get prices linked with the customer id
   * @param id The customer id
   * @returns Linked prices
   */
  async pricesByCustomer(id: string): Promise<CommercePriceGrid[]> {
    const q = query(
      collection(this.firestore, 'price-grid'),
      where('customers', 'array-contains', id))
      .withConverter(CommercePriceGrid.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => doc.data());
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  unsubscribe(): void {
    if (this.unsubscribeCategories) {
      this.unsubscribeCategories();
      this.categories$ = null;
    }
    if (this.unsubscribeOptions) {
      this.unsubscribeOptions.forEach(unsubscribe => unsubscribe());
      this.options$ = null;
    }
  }
}
