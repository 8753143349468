import { FirestoreDataConverter } from '@angular/fire/firestore';
import { ImageModel } from './image.model';
import { Overprint, Quantity } from './commerce-configuration.model';
import { ObjectHelper } from "../helpers/object.helper";
import { FormArray, FormControl, FormGroup } from "@angular/forms";


type ProductionForm = FormGroup<{
  name: FormControl<string>,
  enabled: FormControl<boolean>,
  min: FormControl<number>,
  percentage: FormControl<number>,
  delay: FormControl<number>
}>
export type CommerceProductForm = FormGroup<{
  id: FormControl<string>,
  name: FormControl<string>,
  description: FormControl<string>,
  shortDescription: FormControl<string>,
  detailedDescription: FormControl<string>,
  baitPrice: FormControl<number>,
  variations: FormControl<string[]>,
  overprints: FormControl<Overprint[]>,
  quantities: FormControl<Quantity[]>,
  categories: FormControl<string[]>,
  tags: FormControl<string[]>,
  images: FormControl<ImageModel[]>,
  pdf: FormControl<string>,
  pdfName: FormControl<string>,
  withProof: FormControl<boolean>,
  deliveryDateSelection: FormControl<boolean>,
  fixedDateDeliveries: FormControl<string[]>,
  production: FormArray<ProductionForm>,
  fixedFile: FormControl<boolean>,
  technicalName: FormControl<string>,
  promoteLabel: FormControl<string>,
  promoteLabelIsActive: FormControl<boolean>,
  organizationId: FormControl<string>;
}>

/**
 * Model for Commerce Product (Used for Commerce)
 */
export class CommerceProduct {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  detailedDescription: string;
  baitPrice: number;
  variations: string[];
  overprints: Overprint[];
  quantities: Quantity[];
  categories: string[];
  tags: string[];
  images: ImageModel[];
  pdf: string;
  pdfName: string;
  withProof: boolean;
  deliveryDateSelection: boolean;
  fixedDateDeliveries: string[];
  production: [
    { name: 'standard', enabled: true, min: number, percentage: number, delay: number },
    { name: 'priority', enabled: boolean, min: number, percentage: number, delay: number }
  ];
  defaultVariationId: string;
  fixedFile: boolean;
  technicalName: string;
  promoteLabel: string;
  promoteLabelIsActive: boolean;
  organizationId: string;

  constructor(data?: Partial<CommerceProduct>) {
    this.id = data?.id ?? '';
    this.variations = data?.variations ?? [];
    this.overprints = data?.overprints ?? [];
    this.quantities = data?.quantities ?? [];
    this.categories = data?.categories ?? [];
    this.name = data?.name ?? '';
    this.description = data?.description ?? '';
    this.shortDescription = data?.shortDescription ?? '';
    this.detailedDescription = data?.detailedDescription ?? '';
    this.baitPrice = data?.baitPrice ?? 0;
    this.tags = data?.tags ?? [];
    this.images = data?.images ?? [];
    this.pdf = data?.pdf ?? '';
    this.pdfName = data?.pdfName ?? '';
    this.withProof = data?.withProof ?? false;
    this.deliveryDateSelection = data?.deliveryDateSelection ?? false;
    this.fixedDateDeliveries = data?.fixedDateDeliveries ?? [];
    this.production = data?.production ?? [{
      name: 'standard',
      enabled: true,
      min: 0,
      percentage: 0,
      delay: 0,
    }, {
      name: 'priority',
      enabled: false,
      min: 0,
      percentage: 0,
      delay: 0,
    }];
    this.defaultVariationId = data?.defaultVariationId ?? '';
    this.fixedFile = data?.fixedFile ?? false;
    this.technicalName = data?.technicalName ?? '';
    this.promoteLabel = data?.promoteLabel ?? '';
    this.promoteLabelIsActive = data?.promoteLabelIsActive ?? false;
    this.organizationId = data?.organizationId ?? '';
  }

  /**
   * Converter used to convert a shipment from firebase
   */
  static converter: FirestoreDataConverter<CommerceProduct> = {
    toFirestore: (product: CommerceProduct) => {
      return {
        id: product.id,
        variations: product.variations,
        overprints: ObjectHelper.anonymize(product.overprints),
        quantities: ObjectHelper.anonymize(product.quantities),
        categories: product.categories,
        name: product.name,
        description: product.description,
        shortDescription: product.shortDescription,
        detailedDescription: product.detailedDescription,
        baitPrice: product.baitPrice,
        tags: product.tags,
        images: ObjectHelper.anonymize(product.images),
        pdf: product.pdf,
        pdfName: product.pdfName,
        withProof: product.withProof,
        deliveryDateSelection: product.deliveryDateSelection,
        fixedDateDeliveries: product.fixedDateDeliveries,
        production: product.production,
        defaultVariationId: product.defaultVariationId,
        fixedFile: product.fixedFile,
        technicalName: product.technicalName,
        promoteLabel: product.promoteLabel,
        promoteLabelIsActive: product.promoteLabelIsActive,
        organizationId: product.organizationId,
      };
    },
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new CommerceProduct({
        ...data,
        id: snapshot.id,
      });
    }
  };
}
