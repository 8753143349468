import { FirestoreDataConverter } from '@angular/fire/firestore';
import { RolesEnum } from "../enums/roles.enum";

export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  photoURL: string;
  productionNotification: boolean;
  company: string;
  roles: Record<string, RolesEnum[]>;
  customerIds: string[];
  disabled: false;
  invoicingAddressId: string;
  shippingAddressId: string;
  addressIds: string[];
  userCustomerIds: string[];

  constructor(user?: Partial<User>) {
    this.id = user?.id ?? '';
    this.email = user?.email ?? '';
    this.firstName = user?.firstName ?? '';
    this.lastName = user?.lastName ?? '';
    this.photoURL = user?.photoURL ?? '';
    this.productionNotification = user?.productionNotification ?? false;
    this.company = user?.company ?? '';

    // TODO: Remove after migration:
    if (user && Array.isArray(user.roles)) {
      user.roles = {};
    }
    // TODO: End
    this.roles = user?.roles ?? {};
    this.customerIds = user?.customerIds ?? [];
    this.disabled = user?.disabled ?? false;
    this.invoicingAddressId = user?.invoicingAddressId ?? '';
    this.shippingAddressId = user?.shippingAddressId ?? '';
    this.addressIds = user?.addressIds ?? [];
    this.userCustomerIds = user?.userCustomerIds ?? [];
  }

  get displayName(): string {
    if (!this.firstName && !this.lastName) {
      return this.email;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * Converter used to convert a user from firebase
   */
  static converter: FirestoreDataConverter<User> = {
    toFirestore: (user: User) => ({
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      photoURL: user.photoURL,
      productionNotification: user.productionNotification,
      company: user.company,
      roles: user.roles,
      customerIds: user.customerIds,
      disabled: user.disabled,
      invoicingAddressId: user.invoicingAddressId,
      shippingAddressId: user.shippingAddressId,
      addressIds: user.addressIds,
      userCustomerIds: user.userCustomerIds,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new User({
        ...data,
        id: snapshot.id,
        customerIds: data.customerIds?.map((id: number) => String(id)) ?? []
      });
    }
  };
}
