import { FirestoreDataConverter } from '@angular/fire/firestore';
import { ImportFileTypeEnum } from "../enums/import-file-type.enum";

export class FileImportTask {

  id: string | null;
  orderId: string;
  organizationId: string;
  inProgress: boolean;
  error: string;
  date: Date|null;
  type: ImportFileTypeEnum;

  constructor(task?: Partial<FileImportTask>) {
    this.id = task?.id ?? null;
    this.orderId = task?.orderId ?? '';
    this.organizationId = task?.organizationId ?? '';
    this.inProgress = task?.inProgress ?? false;
    this.error = task?.error ?? '';
    this.date = task?.date ?? null;
    this.type = task?.type ?? ImportFileTypeEnum.COMMERCE;
  }

  static converter: FirestoreDataConverter<FileImportTask> = {
    toFirestore: (task: FileImportTask) => ({
      id: task.id,
      orderId: task.orderId,
      organizationId: task.organizationId,
      inProgress: task.inProgress,
      error: task.error,
      date: task.date,
      type: task.type,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new FileImportTask({
        ...data,
        id: snapshot.id,
        date: data.date ? new Date(data.date.seconds * 1000) : null
      })
    }
  };
}
