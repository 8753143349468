import { FirestoreDataConverter } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type SiteForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  name: FormControl<string>;
  default: FormControl<boolean>;
  organizationId: FormControl<string>;
}>

export class SiteModel {
  id: string;
  name: string;
  erpId: string;
  default: boolean;
  organizationId: string;

  constructor(site?: Partial<SiteModel>) {
    this.id = site?.id ?? '';
    this.name = site?.name ?? '';
    this.erpId = site?.erpId ?? '';
    this.default = site?.default ?? false;
    this.organizationId = site?.organizationId ?? '';
  }

  /**
   * Converter used to convert a site from firebase
   */
  static converter: FirestoreDataConverter<SiteModel> = {
    toFirestore: (site: SiteModel) => ({
      id: site.id,
      name: site.name,
      erpId: site.erpId,
      default: site.default,
      organizationId: site.organizationId,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new SiteModel({
        ...data,
        id: snapshot.id,
      });
    }
  }
}
