import { inject, NgModule } from '@angular/core';
import { CanActivateFn, Router, RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './notfound/notfound.component';
import { AuthGuard } from "./shared/guards/auth.guard";
import { VerifiedGuard } from "./shared/guards/verified.guard";
import { OrganizationService } from "./shared/services/organization.service";

export const hasLocalOrg: CanActivateFn = async route => {
  const organizationService = inject(OrganizationService);
  const router = inject(Router);
  const orgId = route.paramMap.get('organizationId');
  if (orgId) {
    if (organizationService.currentOrganization?.oid === orgId) {
      return true
    } else {
      const organization = await organizationService.getByOid(orgId);
      if (organization) {
        organizationService.currentOrganization = organization;
        return true;
      }
    }
  }
  return router.parseUrl('/');
}

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'quotation',
    loadChildren: () => import('./quotation/quotation.module')
      .then(m => m.QuotationModule),
  },
  {
    path: 'bat',
    loadChildren: () => import('./bat/bat.module')
      .then(m => m.BatModule),
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./subscribe/subscribe.module')
      .then(m => m.SubscribeModule),
  },
  {
    path: 'templates',
    loadChildren: () => import('./templates/templates.module')
      .then(m => m.TemplatesModule),
  },
  {
    path: ':organizationId',
    loadChildren: () => import('./admin/admin.module')
      .then(m => m.AdminModule),
    canActivate: [hasLocalOrg, VerifiedGuard]
  },
  {
    path: ':organizationId/shipment',
    loadChildren: () => import('./shipment/shipment.module')
      .then(m => m.ShipmentModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/bat',
    loadChildren: () => import('./bat/bat.module')
      .then(m => m.BatModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/quotation',
    loadChildren: () => import('./quotation/quotation.module')
      .then(m => m.QuotationModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/order',
    loadChildren: () => import('./order/order.module')
      .then(m => m.OrderModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/templates',
    loadChildren: () => import('./templates/templates.module')
      .then(m => m.TemplatesModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/transport',
    loadChildren: () => import('./transport/transport.module')
      .then(m => m.TransportModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/installation',
    loadChildren: () => import('./installation/installation.module')
      .then(m => m.InstallationModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/external-production',
    loadChildren: () => import('./external-production/external-production.module')
      .then(m => m.ExternalProductionModule),
    canActivate: [hasLocalOrg],
  },
  {
    path: ':organizationId/production',
    loadChildren: () => import('./production/production.module')
      .then(m => m.ProductionModule),
    canActivate: [hasLocalOrg],
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { /** enableTracing: true, **/ scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})

/**
 * Main application routing module declaration
 */
export class AppRoutingModule {
}
