import { FirestoreDataConverter } from '@angular/fire/firestore';
import { QuotationState } from '../enums/quotation-state.enum';
import { Notification } from '../enums/notification.enum';
import { ProductOrder } from "./product-order.model";
import { ObjectHelper } from "../helpers/object.helper";
import { SourceType } from "../enums/source-type.enum";

export class Quotation {

  id: string;
  description: string;
  email: string;
  contactName: string;
  reference: string;
  company: string;
  state: QuotationState;
  customerId: string;
  date: Date;
  emailSent: Notification;
  reason: string;
  signature: string;
  sales: ({ id: string, name: string, email: string });
  calculator: ({ id: string, name: string, email: string });
  lockComment: string;
  lockedBy = 'none';
  lockerName: string;
  lockDate: Date;
  validationDate: Date;
  source: SourceType;
  erpId: string;
  organizationId: string;
  version: number;
  products: ProductOrder[];
  contacts: string[];
  transactionId: string;
  recallDate: Date;

  constructor(quotation?: Partial<Quotation>) {
    this.id = quotation?.id ?? '';
    this.description = quotation?.description ?? '';
    this.email = quotation?.email ?? '';
    this.contactName = quotation?.contactName ?? '';
    this.reference = quotation?.reference ?? '';
    this.company = quotation?.company ?? '';
    this.state = quotation?.state ?? QuotationState.NEW;
    this.customerId = quotation?.customerId ?? '';
    this.date = quotation?.date ? new Date(quotation.date) : null;
    this.emailSent = quotation?.emailSent ?? Notification.NONE_SENT;
    this.reason = quotation?.reason ?? '';
    this.signature = quotation?.signature ?? '';
    this.lockComment = quotation?.lockComment ?? '';
    this.lockedBy = quotation?.lockedBy ?? 'none';
    this.lockerName = quotation?.lockerName ?? '';
    this.lockDate = quotation?.lockDate ?? null;
    this.validationDate = quotation?.validationDate ?? null;
    this.sales = {
      id: quotation?.sales?.id ?? '',
      name: quotation?.sales?.name ?? '',
      email: quotation?.sales?.email ?? '',
    };
    this.calculator = {
      id: quotation?.calculator?.id ?? '',
      name: quotation?.calculator?.name ?? '',
      email: quotation?.calculator?.email ?? '',
    };
    this.source = quotation?.source ?? SourceType.OKTO;
    this.erpId = quotation?.erpId ?? '';
    this.organizationId = quotation?.organizationId ?? '';
    this.version = quotation?.version ?? 1;
    this.products = (quotation?.products ?? []).map(product => new ProductOrder(product));
    this.contacts = quotation?.contacts ?? [];
    this.transactionId = quotation?.transactionId ?? '';
    this.recallDate = quotation?.recallDate ? new Date(quotation.recallDate) : null;
  }

  /**
   * Converter used to convert a user from firebase
   */
  static converter: FirestoreDataConverter<Quotation> = {
    toFirestore: (quotation: Quotation) => ({
      id: quotation.id,
      description: quotation.description,
      email: quotation.email,
      contactName: quotation.contactName,
      reference: quotation.reference,
      company: quotation.company,
      state: quotation.state,
      customerId: quotation.customerId,
      date: quotation.date,
      reason: quotation.reason,
      signature: quotation.signature,
      emailSent: quotation.emailSent,
      sales: quotation.sales,
      calculator: quotation.calculator,
      lockComment: quotation.lockComment,
      lockedBy: quotation.lockedBy,
      lockerName: quotation.lockerName,
      lockDate: quotation.lockDate,
      validationDate: quotation.validationDate,
      source: quotation.source,
      erpId: quotation.erpId,
      organizationId: quotation.organizationId,
      version: quotation.version,
      products: ObjectHelper.anonymize(quotation.products),
      contacts: quotation.contacts,
      transactionId: quotation.transactionId,
      recallDate: quotation.recallDate,
    }),
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      return new Quotation({
        ...data,
        id: snapshot.id,
        date: data.date ? new Date(data.date.seconds * 1000) : null,
        recallDate: data.recallDate ? new Date(data.recallDate.seconds * 1000) : null,
        validationDate: data.validationDate ? new Date(data.validationDate.seconds * 1000) : null,
        lockDate: data.lockDate ? new Date(data.lockDate.seconds * 1000) : null,
        products: (data.products ?? []).map((product: any) => ({
          ...product,
          deliveryDate: product.deliveryDate ? new Date(product.deliveryDate.seconds * 1000) : null
        })),
      });
    }
  }
}
