import { QueryDocumentSnapshot, snapToData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from "@angular/forms";

export type PrintTypeForm = FormGroup<{
  id: FormControl<string>;
  erpId: FormControl<string>;
  description: FormControl<string>;
  sides: FormControl<number>;
  organizationId: FormControl<string>;
}>

export class Printing {
  id: string;
  erpId: string;
  description: string;
  sides: number;
  organizationId: string;

  constructor(data?: Partial<Printing>) {
    this.id = data?.id ?? '';
    this.erpId = data?.erpId ?? '';
    this.description = data?.description ?? '';
    this.sides = data?.sides ?? 1;
    this.organizationId = data?.organizationId ?? '';
  }

  static toFirestore(printing: Printing) {
    return {
      id: printing.id,
      erpId: printing.erpId,
      description: printing.description,
      sides: printing.sides,
      organizationId: printing.organizationId,
    };
  }

  static fromFirestore(snapshot: QueryDocumentSnapshot) {
    return new Printing(snapToData(snapshot, { idField: 'id' }));
  }
}
