import { inject, Injectable } from '@angular/core';
import { User } from '../models/user.model';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where
} from '@angular/fire/firestore';
import { ObjectHelper } from "../helpers/object.helper";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private firestore = inject(Firestore);

  /**
   * Get a firebase user
   * @param uid The user id
   * @returns The user or null
   */
  public async getUserByUid(uid: string): Promise<User> {
    const ref = doc(this.firestore, 'users', uid).withConverter(User.converter);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      return null;
    }
    return docSnap.data();
  }

  /**
   * Save a user in firebase
   * @param id the id of the user
   * @param user The field of the user to update
   * @returns Update doc result
   */
  public saveUser(id: string, user: Partial<User>): Promise<void> {
    const ref = doc(this.firestore, 'users', id)
      .withConverter(User.converter);
    return updateDoc(ref, ObjectHelper.anonymize(user));
  }

  /**
   * Get all users
   * @returns A query to listen users changes
   */
  public async listUsers(): Promise<User[]> {
    const q = query(collection(this.firestore, 'users'),
      orderBy('lastName', 'asc'))
      .withConverter(User.converter);
    return getDocs(q).then(snapshot => {
      if (snapshot.empty) {
        return [];
      }
      return snapshot.docs.map(doc => doc.data());
    }).catch(err => {
      console.error(err);
      throw err;
    });
  }

  /**
   * Get all users
   * @param uIds The ids to fetch
   * @returns A query to listen users changes
   */
  public async getUsers(uIds: string[]): Promise<User[]> {
    const promises: Promise<User[]>[] = []
    const uniqueIds = [...new Set(uIds)];
    const ref = collection(this.firestore, 'users');
    for (let i = 0; i < uniqueIds.length; i += 10) {
      const idList = uniqueIds.slice(i, i + 10);
      const q = query(ref, where('id', 'in', idList))
        .withConverter(User.converter);
      const promise = getDocs(q)
        .then(snapshot => snapshot.docs.map(doc => doc.data()));
      promises.push(promise);
    }
    return Promise.all(promises)
      .then(arrayOfUsers => {
        const users = arrayOfUsers.flat();
        users.sort((a, b) => a.displayName.localeCompare(b.displayName))
        return users;
      })
  }

  /**
   * Get users linked with the customer id
   * @param id The customer id
   * @returns Linked users
   */
  async usersByCustomer(id: string): Promise<User[]> {
    const q = query(
      collection(this.firestore, 'users'),
      where('customerIds', 'array-contains', Number(id))) // TODO: customer ids could be strings
      .withConverter(User.converter);
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      return [];
    }
    return snapshot.docs.map(doc => doc.data());
  }
}
